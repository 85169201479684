import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from '@react-navigation/native';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import { BlurView } from 'expo-blur';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState, useRef } from 'react';
import {
  AppState,
  AppStateStatus,
  ColorSchemeName,
  useWindowDimensions,
} from 'react-native';
import appsFlyer from 'react-native-appsflyer';

import ActivityResponder from '../components/ActivityResponder';
import ConditionalWrapper from '../components/Common/ConditionalWrapper';
import Toast from '../components/Common/Toast';
import ExternalLink from '../components/Common/ExternalLink';
import config from '../config';
import ConfirmEmail from '../containers/ConfirmEmail';
import UgamiCardApplication from '../containers/UgamiCardApplication';
import OnboardingDone from '../containers/OnboardingDone/index'

import { useStore } from '../stores';
import {
  initAppsFlyer,
  trackWithProperties,
} from '../utils/analytics';
import LinkingConfiguration from './LinkingConfiguration';
import AuthStack from './stacks/AuthStack';
import PostRegistrationStack from './stacks/PostRegistrationStack';
export  type { AuthStackNavigation, AuthStackParamList } from './stacks/AuthStack';
export type {
  PostRegistrationStackNavigation,
  PostRegistrationStackParamList,
} from './stacks/PostRegistrationStack';

// const CleverTap = require('clevertap-react-native');

export type AppStackParamList = {
  'External Link': { url: string; title?: string };
  'Apply for Ugami Card': undefined;
  OnboardingDone: undefined;
  ConfirmEmail: undefined;
};
export type AppStackNavigation = StackNavigationProp<AppStackParamList>;

type Props = {
  colorScheme: ColorSchemeName;
};

const Stack = createStackNavigator<AppStackParamList>();

const AppStack: React.FC = () => (
  <Stack.Navigator
    screenOptions={{
      cardStyle: { backgroundColor: 'transparent' },
      animationEnabled: false,
      headerShown: false,
      presentation: 'modal',
    }}
  >
    <Stack.Screen name="Apply for Ugami Card" component={UgamiCardApplication} />
    <Stack.Screen name="External Link" component={ExternalLink} />
    <Stack.Screen name="ConfirmEmail" component={ConfirmEmail} />
    <Stack.Screen name="OnboardingDone" component={OnboardingDone} />
  </Stack.Navigator>
);

const Stacks = {
  AppStack,
  AuthStack,
  PostRegistrationStack,
};

const AppContainer: React.FC<Props> = ({ colorScheme }) => {
  const appState = useRef(AppState.currentState);
  const store = useStore();
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const {
    isOnboardingDone,
    isSignInComplete,
    isSignUpComplete,
    toastData,
    showToast,
    showToastMessage,
  } = store;
  const { height, width } = useWindowDimensions();

  const [key, setKey] = useState<keyof typeof Stacks>('AuthStack');

  const Stack = Stacks[key];

  useEffect(() => {
    setKey(
      isSignUpComplete
        ? 'PostRegistrationStack'
        : isSignInComplete
        ? 'AppStack'
        : 'AuthStack',
    );
  }, [isOnboardingDone, isSignInComplete, isSignUpComplete]);


  useEffect(() => {
    store.setSelectedStack(key);
  }, [Stack]);

  useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange);
    return () => {
      AppState.removeEventListener('change', handleAppStateChange);
    };
  }, []);

  useEffect(() => {
    const appsFlyerUnifiedDeepLinkListener = appsFlyer.onDeepLink((res) => {
      if (res?.deepLinkStatus !== 'NOT_FOUND') {
        const deepLinkValue = res?.data?.deep_link_value;
        if (deepLinkValue === 'referred_signup') {
          const referrerCode = res?.data?.deep_link_sub1;
          store.setReferrerCode(referrerCode!);
          store.setIsNewlyOpen(false);
        }
        trackWithProperties('Deeplinked to App', { deepLinkValue });
        store.setDeepLink(deepLinkValue);
      }
    });
    initAppsFlyer();
    return () => {
      appsFlyerUnifiedDeepLinkListener();
    };
  }, []);

  // useEffect(() => {
  //   const clevertapInAppListener = CleverTap.addListener(
  //     CleverTap.CleverTapInAppNotificationButtonTapped,
  //     async (event: any) => {
  //       const { value, url } = event;
  //       if (value) {
  //         store.setDeepLink(value);
  //       } else {
  //         const validUrl = await Linking.canOpenURL(url);
  //         if (!validUrl) {
  //           return;
  //         }
  //         const queryParams = qs.parse(url.replace(/.*\?/, ''));
  //         const deepLinkValue = queryParams.deep_link_value as string;
  //         if (deepLinkValue) {
  //           store.setDeepLink(deepLinkValue);
  //         } else {
  //           Linking.openURL(url);
  //         }
  //       }
  //     },
  //   );
  //   const clevertapNativeDisplayListener = CleverTap.addListener(
  //     CleverTap.CleverTapDisplayUnitsLoaded,
  //     (event: any) => {
  //       getAllDisplayUnits();
  //     },
  //   );

  //   return () => {
  //     clevertapInAppListener();
  //     clevertapNativeDisplayListener();
  //   };
  // }, []);

  const handleAppStateChange = (nextAppState: AppStateStatus) => {
    appState.current = nextAppState;
    setAppStateVisible(appState.current);
  };

  return (
    <ConditionalWrapper
      condition={isSignInComplete && !isSignUpComplete}
      wrapper={(children: any) => (
        <ActivityResponder>{children}</ActivityResponder>
      )}
    >
      <NavigationContainer
        theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
        linking={{
          prefixes: config.deepLinkPrefixes,
          config: LinkingConfiguration[key],
        }}
      >
        <Stack />
        {showToast && (
          <Toast
            message={toastData?.message || ''}
            visible={showToast}
            setVisible={(_value: boolean) => showToastMessage(false, null)}
            toastDuration={toastData?.duration || 1000}
            {...toastData}
          />
        )}
      </NavigationContainer>
      {appStateVisible !== 'active' && (
        <BlurView
          intensity={100}
          style={{
            width,
            height,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
      )}
    </ConditionalWrapper>
  );
};

export default observer(AppContainer);