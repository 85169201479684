import { useStyleSheet, StyleService, Text } from "@ui-kitten/components";
import React from "react";
import { View, Image } from "react-native";
import PlayStore from "../PlayStore";
import background from "http://ugami.com/wp-content/uploads/2022/07/ugami-home-cover-section-bgnd-fallback.png";
type Props = object;
const OnboardingDoneDesktop: React.FC<Props> = (_props) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <View style={styles.container}>
      <View style={styles.subContainerOne}>
        <View style={styles.titleBox}>
          <Text style={styles.title} category={"h1"}>
            JUST ONE STEP CLOSER
          </Text>
          <Text style={styles.title} category={"h1"}>
            TO BECOMING AN
          </Text>
          <Text style={styles.title} category={"h1"}>
            UGAMIAN!
          </Text>
        </View>
        <View style={styles.titleBox}>
          <Text category={"s2"}>
            Pay for everything with your Debit Card for Gamers,
          </Text>
          <Text category={"s2"}>and get amazing rewards designed for you!</Text>
          <View style={styles.playstore}>
            <PlayStore device={"desktop"} />
          </View>
        </View>
      </View>
      <View style={styles.subContainerTwo}>
        <Image
          style={styles.ugamiImage}
          source={{
            uri: "https://ugami.com/wp-content/uploads/2022/08/hero_phone_static.png",
          }}
        />
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    display: "flex",
    height: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    background:
      'url("http://ugami.com/wp-content/uploads/2022/07/ugami-home-cover-section-bgnd-fallback.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  subContainerOne: {
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "left",
    width: "45%",
  },
  subContainerTwo: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alingItems: "center",
  },
  ugamiImage: {
    resizeMode: "contain",
    flex: 1,
    aspectRatio: 1.5,
  },
  title: {
    fontWeight: "bold",
  },
  titleBox: {
    width: "60%",
    marginBottom: 20,
  },
  playstore: {
    paddingTop: 25,
  },
});

export default OnboardingDoneDesktop;
