import camelCaseKeys from 'camelcase-keys';
import ReconnectingWebSocket from 'reconnecting-websocket';

import config from '../../config';

export type MessageEventData = {
  type: string;
  payload: any;
};

export default class WebSocketConnection {
  [handlerName: string]: Function | any;

  private rws: ReconnectingWebSocket;
  private callback: (data: MessageEventData) => void;

  constructor(token: string, callback: (data: MessageEventData) => void) {
    const ws = `${config.urls.ws}?token=${token}`;
    console.log('[DEBUG][WebSocket] Connecting to', ws);

    this.callback = callback;
    this.rws = new ReconnectingWebSocket(ws);
    this.rws.addEventListener('open', () => {
      console.log('[DEBUG][WebSocket] Connected to', ws);
    });
    this.rws.addEventListener('close', (e) => {
      console.log(
        '[DEBUG][WebSocket] Closing connection',
        `${e.type}${e.code}: ${e.reason}. ${e.wasClean}`,
      );
      console.log('[DEBUG][WebSocket] Closing connection', ws);
    });
    this.rws.addEventListener('error', (event) => {
      console.log('[ERROR][WebSocket]', event.message, event.error);
      console.log('[ERROR][WebSocket]', ws);
    });
    this.rws.addEventListener('message', (event) => {
      this.callback(
        camelCaseKeys(JSON.parse(event.data), {
          deep: true,
        }),
      );
    });
  }

  close() {
    if (this.rws.readyState === ReconnectingWebSocket.OPEN) {
      this.rws.close();
    }
    console.log(
      '[DEBUG][WebSocket] Connection to',
      config.urls.ws,
      'is closed',
    );
  }

  reconnect() {
    if (this.rws.readyState === ReconnectingWebSocket.OPEN) {
      this.rws.reconnect();
      console.log('[DEBUG][WebSocket] Reconnecting to', config.urls.ws);
    }
  }
}
