import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import * as eva from '@eva-design/eva';
import { EvaIconsPack } from '@ui-kitten/eva-icons';

import WebSocket from './components/WebSocket';
import Colors from './constants/Colors';
import Navigation from './navigation';
import { StoreProvider } from './stores';
import useColorScheme from './hooks/useColorScheme';
import useInit from './hooks/useInit';
import { initAnalytics } from './utils/analytics';
import Mapping from './constants/Mapping/index';
import './App.css';

export default function App() {
  const { store } = useInit();
  const statusBar = colorScheme === 'dark' ? 'light' : 'dark';
  const colorScheme = useColorScheme();
  initAnalytics()
  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      <StatusBar style={statusBar} />
      <ApplicationProvider
        mapping={eva.mapping}
        customMapping={Mapping}
        theme={Colors[colorScheme]}
      >
        <StoreProvider value={store}>
          <SafeAreaProvider>
            <Navigation colorScheme={colorScheme} />
            <WebSocket />
          </SafeAreaProvider>
        </StoreProvider>
      </ ApplicationProvider>
    </>

  );
}


