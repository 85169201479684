const avatars = [
    {
        name: 'axel.png',
        image: require('../assets/images/axel.png')
    },
    {
        name: 'joel-jones.png',
        image: require('../assets/images/joel-jones.png'),
    },
    {
        name: 'velox-vir.png',
        image: require('../assets/images/velox-vir.png'),
    },
    {
        name: 'vahladar.png',
        image: require('../assets/images/vahladar.png'),
    },
    {
        name: 'darius-krueger.png',
        image: require('../assets/images/darius-krueger.png'),
    },
    {
        name: 'faelle-vexpin.png',
        image: require('../assets/images/faelle-vexpin.png'),
    },
    {
        name: 'thunak-ral.png',
        image: require('../assets/images/thunak-ral.png'),
    },
    {
        name: 'trish-tucker.png',
        image: require('../assets/images/trish-tucker.png'),
    },
    {
        name: 'sizo.png', image:
            require('../assets/images/sizo.png')
    },
    {
        name: 'jada-jules.png',
        image: require('../assets/images/jada-jules.png'),
    },
    {
        name: 'odie-oller.png',
        image: require('../assets/images/odie-oller.png'),
    },
    {
        name: 'isaac-robins.png',
        image: require('../assets/images/isaac-robins.png'),
    },
    {
        name: 'nanthu-pai.png',
        image: require('../assets/images/nanthu-pai.png'),
    },
    {
        name: 'brad-blockey.png',
        image: require('../assets/images/brad-blockey.png'),
    },
    {
        name: 'vicenz-vitori.png',
        image: require('../assets/images/vicenz-vitori.png'),
    },
    {
        name: 'lauren-park.png',
        image: require('../assets/images/lauren-park.png'),
    },
    {
        name: 'alice-park.png',
        image: require('../assets/images/alice-park.png'),
    },
    {
        name: 'price-phylos.png',
        image: require('../assets/images/price-phylos.png'),
    },
    {
        name: 'lothar-lodrick.png',
        image: require('../assets/images/lothar-lodrick.png'),
    },
    {
        name: 'grog.png', image:
            require('../assets/images/grog.png')
    },
    {
        name: 'cacophonus.png',
        image: require('../assets/images/cacophonus.png'),
    },
    {
        name: 'lydia-goldheart.png',
        image: require('../assets/images/lydia-goldheart.png'),
    },
    {
        name: 'rakin-ling.png',
        image: require('../assets/images/rakin-ling.png'),
    },
    {
        name: 'claire-ludric.png',
        image: require('../assets/images/claire-ludric.png'),
    },
    {
        name: 'ashkepha.png',
        image: require('../assets/images/ashkepha.png'),
    },
    {
        name: 'anisia-salonis.png',
        image: require('../assets/images/anisia-salonis.png'),
    },
    {
        name: 'anthony-tejas.png',
        image: require('../assets/images/anthony-tejas.png'),
    },
    {
        name: 'aspiax.png',
        image: require('../assets/images/aspiax.png')
    },
    {
        name: 'chris-crone.png',
        image: require('../assets/images/chris-crone.png'),
    },
    {
        name: 'dreaded-cutter.png',
        image: require('../assets/images/dreaded-cutter.png'),
    },
    {
        name: 'drelya.png',
        image: require('../assets/images/drelya.png')
    },
    {
        name: 'forgotten-one.png',
        image: require('../assets/images/forgotten-one.png'),
    },
    {
        name: 'lilly.png',
        image: require('../assets/images/lilly.png')
    },
    {
        name: 'frontrider.png',
        image: require('../assets/images/frontrider.png'),
    },
    {
        name: 'gasha-greor.png',
        image: require('../assets/images/gasha-greor.png'),
    },
    {
        name: 'lurkael.png',
        image: require('../assets/images/lurkael.png')
    },
    {
        name: 'pia.png',
        image: require('../assets/images/pia.png')
    },
    {
        name: 'roy-fritz.png',
        image: require('../assets/images/roy-fritz.png'),
    },
    {
        name: 'villatrox.png',
        image: require('../assets/images/villatrox.png'),
    },
    {
        name: 'zhuri-zhan.png',
        image: require('../assets/images/zhuri-zhan.png'),
    },
    {
        name: 'mortis-mcar.png',
        image: require('../assets/images/mortis-mcar.png'),
    },
    {
        name: 'murlem.png',
        image: require('../assets/images/murlem.png')
    },
    {
        name: 'kevin-jules.png',
        image: require('../assets/images/kevin-jules.png'),
    },
    {
        name: 'goh-mank.png',
        image: require('../assets/images/goh-mank.png'),
    },
    {
        name: 'shiloh-parks.png',
        image: require('../assets/images/shiloh-parks.png'),
    },
    {
        name: 'kevin-jules-mech.png',
        image: require('../assets/images/kevin-jules-mech.png'),
    },
];

export default avatars