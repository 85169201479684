import {
  useStyleSheet,
  StyleService,
  Text,
  Button,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { View } from 'react-native';

import ContainerScrollView from '../../components/Common/ContainerScrollView';
import { AccountType } from '../../constants/AccountType';
import CardSelector from './CardSelector';
import Table from './Table';

type Props = {
  accountType: AccountType;
  setAccountType: (type: AccountType) => void;
  onNext: () => void;
};

const UgamiCardSelector: React.FC<Props> = ({
  accountType,
  setAccountType,
  onNext,
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <ContainerScrollView containerStyle={styles.containerStyle}>
      <View style={styles.headerContainer}>
        <Text style={styles.heading}>Select Your Ugami Card</Text>
        <Text style={styles.heading2}>
          With two different types of Ugami cards, you can find the perfect card
          for you!
        </Text>
      </View>
      <Table accountType={accountType} setAccountType={setAccountType} />
      <Text style={styles.selectedText}>You've selected</Text>
      <Text style={styles.cardType}>
        {accountType === AccountType.DEBIT_BOOSTED
          ? 'Boosted Debit'
          : accountType}
      </Text>
      <CardSelector accountType={accountType} setAccountType={setAccountType} />
      <Text style={[styles.bottomText, styles.bottomTextHeading]}>
        Thanks for joining Ugami!
      </Text>
      <Text style={styles.bottomText}>
        Your account will be{' '}
        <Text style={[styles.bottomText, styles.bottomTextBold]}>Boosted</Text>{' '}
        for <Text style={[styles.bottomText, styles.bottomTextBold]}>FREE</Text>{' '}
        until{' '}
        <Text style={[styles.bottomText, styles.bottomTextBold]}>9/30/22</Text>
        !
      </Text>
      <Button
        style={styles.button}
        onPress={onNext}
        size="large"
        testID="UgamiCardSelectorSignUpButton"
      >
        {accountType === AccountType.DEBIT_BOOSTED
          ? 'APPLY FOR BOOSTED DEBIT'
          : 'APPLY FOR DEBIT'}
      </Button>
    </ContainerScrollView>
  );
};

const themedStyles = StyleService.create({
  containerStyle: {
    paddingHorizontal: -16,
  },
  headerContainer: {
    marginHorizontal: 16,
    marginBottom: 24,
    alignSelf: 'stretch',
    alignItems: 'center',
  },
  heading: {
    fontSize: 24,
    fontFamily: 'Jura_700Bold',
    textAlign: 'center',
    marginBottom: 12,
  },
  heading2: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
    textAlign: 'center',
    marginHorizontal: 20,
    marginBottom: 8,
  },
  bottomTextHeading: {
    fontSize: 18,
    lineHeight: 22,
    marginTop: 24,
  },
  bottomText: {
    fontSize: 14,
    lineHeight: 18,
    fontFamily: 'SourceSansPro_600SemiBold',
    color: 'text-light-secondary',
    textAlign: 'center',
    marginTop: 12,
    marginHorizontal: 40,
  },
  bottomTextBold: {
    marginLeft: 0,
    marginRight: 0,
    fontFamily: 'SourceSansPro_700Bold',
  },
  selectedText: {
    fontSize: 23,
    lineHeight: 30.2,
    fontFamily: 'Jura_700Bold',
    textAlign: 'center',
    marginTop: 22,
  },
  cardType: {
    fontSize: 18,
    lineHeight: 30.2,
    fontFamily: 'SourceSansPro_600SemiBold',
    textAlign: 'center',
    marginBottom: 16,
  },
  button: {
    marginHorizontal: 16,
    marginTop: 24,
    marginBottom: 24,
  },
});

export default observer(UgamiCardSelector);
