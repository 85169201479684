import React from 'react';
import {
    IconProps,
    Icon,
    useTheme,
} from '@ui-kitten/components';

const ArrowIcon = (_props: IconProps) => {
    const theme = useTheme();
    return (
        <Icon
            name="arrow-forward"
            width={20}
            height={20}
            fill={theme['app-background']}
        />
    )
};

export default ArrowIcon;