import { useNavigation } from '@react-navigation/native';
import {
  useStyleSheet,
  StyleService,
  Button,
  Text,
} from '@ui-kitten/components';
import { Video } from 'expo-av';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
  View,
  Image,
  StyleProp,
  ImageStyle,
  TouchableOpacity,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from "react-responsive";

import Celebration from '../../assets/videos/celebration_animation.m4v';
import Header from '../../components/Header';
import {
  AppStackNavigation,
  PostRegistrationStackNavigation,
} from '../../navigation';
import { useStore } from '../../stores';

type Props = object;

const ConfirmEmail: React.FC<Props> = (_props) => {
  const styles = useStyleSheet(themedStyles);
  const store = useStore();
  const insets = useSafeAreaInsets();
  const appNavigation = useNavigation<AppStackNavigation>();
  const postregNavigation = useNavigation<PostRegistrationStackNavigation>();
  const containerRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const [width, setWidth] = useState(0);
  const {
    authStore,
    userStore,
    showToastMessage,
    selectedStack,
  } = store;
  const [loading, setLoading] = useState(true);
  const user = authStore.user;
  useEffect(() => {
    if (user && user?.hasVerifiedEmail) {
      postregNavigation.navigate('CreateAccountComplete');
    }
  }, [user?.hasVerifiedEmail, selectedStack]);

  const onUpdateEmail = () => {
    postregNavigation.navigate('Update Email');
  };

  const onResendVerification = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    await userStore.resendEmailVerification();
    setLoading(false);
    showToastMessage(true, {
      message: 'Email verification successfully sent!',
      success: true,
    });
  }, [loading]);

  const onClose = () => {
    if (user?.temporaryEmail) {
      postregNavigation.goBack();
    } else {
      appNavigation.goBack();
    }
  };

  if (!user) {
    return null;
  }

  useLayoutEffect(() => {
    setWidth(containerRef.current.offsetWidth);
  }, []);

  return (
    <View style={isMobile ? {} : { alignItems: 'center' }}>
      <View style={styles.container} testID="ConfirmEmailScreen" ref={containerRef}>
        <Video
          isMuted
          resizeMode="cover"
          shouldPlay
          source={Celebration}
          style={isMobile ? [styles.container, { width: width }] : styles.video}
        />
        {!!user.temporaryEmail && (
          <Header
            isChildStack={false}
            title=""
            rightButtonIcon="close"
            rightButtonOnPress={onClose}
            style={styles.header}
          />
        )}
        <View style={isMobile ? styles.overlayMobile : styles.overlay}>
          <Header title="" isChildStack={false} />
          <View style={styles.content}>
            <View style={styles.formContainer}>
              {!user || user?.profileImage === '' ? (
                <View style={styles.avatar} />
              ) : (
                <Image
                  style={styles.avatar as StyleProp<ImageStyle>}
                  source={{ uri: user.profileImage }}
                />
              )}
              <Text style={styles.gamertag}>
                {user?.gamerTag || ''}
                <Text style={styles.number}>{` #${user?.gamerTagNumber || ''
                  }`}</Text>
              </Text>
              <Text style={styles.title}>Verify Your Email</Text>
              <Text style={styles.message}>
                An email has been sent to{' '}
                <Text style={styles.email}>
                  {user.temporaryEmail || user.email}
                </Text>
                . Please check your inbox and verify your email to continue.
              </Text>
              <View style={styles.spacer} />
              <Button
                size="large"
                style={styles.button}
                onPress={!user.temporaryEmail ? onUpdateEmail : onClose}
                disabled={loading}
              >
                {!user.temporaryEmail ? 'UPDATE EMAIL' : 'CLOSE'}
              </Button>

              <Text style={styles.secondaryText}>Did not receive an email?</Text>
              <TouchableOpacity onPress={onResendVerification} disabled={loading}>
                <Text
                  style={[styles.resend, { marginBottom: insets.bottom + 20 }]}
                >
                  Resend Email
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  video: {
    width: 570,
    top: '10%'
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  content: {
    flex: 1,
    paddingTop: '30%',
    width: '100%',
  },
  overlayMobile: {
    position: 'absolute',
    backgroundColor: 'transparent',
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  overlay: {
    position: 'absolute',
    backgroundColor: 'transparent',
    height: '100%',
    width: '100%',
    alignItems: 'center',
  },
  imageBg: {
    flex: 1,
    resizeMode: 'cover',
  },
  formContainer: {
    flex: 1,
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    fontFamily: 'Jura_600SemiBold',
    paddingTop: 30,
    paddingBottom: 12,
  },
  message: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
    textAlign: 'center',
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
  email: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_600SemiBold',
    color: 'red-02',
  },
  secondaryText: {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'SourceSansPro_400Regular',
  },
  resend: {
    fontSize: 14,
    lineHeight: 16,
    fontFamily: 'SourceSansPro_700Bold',
    color: 'red-02',
    textAlign: 'center',
    paddingVertical: 8,
    paddingHorizontal: 32,
  },
  avatar: {
    width: 170,
    height: 170,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: 'gray-02',
    backgroundColor: 'brand-field-background',
    marginBottom: 16,
  },
  gamertag: {
    fontSize: 18,
    fontFamily: 'SourceSansPro_700Bold',
  },
  number: {
    fontSize: 18,
    fontFamily: 'SourceSansPro_400Regular',
    paddingBottom: 60,
    opacity: 0.5,
  },
  spacer: {
    flex: 1,
  },
  button: {
    alignSelf: 'stretch',
    marginBottom: 28,
    marginHorizontal: 16,
  },
});

export default observer(ConfirmEmail);
