import {
  useStyleSheet,
  StyleService,
  Text,
  useTheme,
} from '@ui-kitten/components';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';
import { View } from 'react-native';
import {
  GooglePlacesAutocomplete,
  GooglePlaceDetail,
} from 'react-native-google-places-autocomplete';

import config from '../../config';

type Props = {
  placeholder: string;
  defaultValue?: string;
  value?: string;
  setStreet1: (value: string) => void;
  setCity: (value: string) => void;
  setState: (value: string) => void;
  setPostalCode: (value: string) => void;
  label?: string;
  onFocus?: () => void;
  onBlur?: () => void;
};

const AddressAutoComplete: React.FC<Props> = ({
  label,
  placeholder,
  defaultValue,
  value,
  setStreet1,
  setCity,
  setState,
  setPostalCode,
  onFocus,
  onBlur,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [isFocus, setIsFocus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();
  const onInputFocus = () => {
    if (onFocus) {
      onFocus();
    }
    setErrorMessage('');
    setIsFocus(true);
  };

  const onInputBlur = () => {
    if (onBlur) {
      onBlur();
    }
    setIsFocus(false);
  };

  const onAutoCompletePress = (_: any, details: GooglePlaceDetail | null) => {
    let address1 = '';
    if (details) {
      setStreet1('');
      setCity('');
      setState('');
      setPostalCode('');
      details.address_components.forEach((component) => {
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }
          case 'route': {
            address1 += component.short_name;
            break;
          }
          case 'postal_code': {
            setPostalCode(component.long_name);
            break;
          }
          case 'locality':
            setCity(component.long_name);
            break;
          case 'administrative_area_level_1': {
            setState(component.long_name);
            break;
          }
        }
      });
      setStreet1(address1);
    }
  };

  return (
    <>
      {label ? (
        <View style={styles.label}>
          <View style={styles.row}>
            <Text style={styles.textWithOpacity}>{label}</Text>
          </View>
          {!!errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
        </View>
      ) : (
        <View style={styles.emptyLabel}>
          {!!errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
        </View>
      )}
      <View style={styles.inputContainer}>
        <LinearGradient
          colors={[
            errorMessage !== ''
              ? theme['orange-03']
              : isFocus
              ? theme['red-03']
              : 'transparent',
            'transparent',
          ]}
          style={[
            errorMessage || isFocus
              ? styles.gradientInputContainer
              : styles.inputContainer,
            styles.gradient,
          ]}
        />
        <GooglePlacesAutocomplete
          placeholder={placeholder}
          onPress={onAutoCompletePress}
          query={{
            key: config.googlePlacesApiKey,
            language: 'en',
            components: 'country:us',
            fields: ['address_components', 'geometry'],
            types: ['address'],
          }}
          textInputProps={{
            defaultValue,
            value,
            returnKeyType: 'done',
            placeholderTextColor: `${theme['text-placeholder']}`,
            onChangeText: setStreet1,
            onFocus: onInputFocus,
            onBlur: onInputBlur,
            clearButtonMode: 'never',
            testID: 'Street1Input',
          }}
          onFail={(_) => console.log('[DEBUG] Error at finding location')}
          styles={{
            container: styles.container,
            textInput:
              errorMessage || isFocus
                ? styles.textInputFocus
                : styles.textInputBlur,
            textInputContainer: styles.textInputContainer,
            row: styles.addressRow,
            description: styles.description,
            listView: styles.listView,
            separator: {
              height: 0,
            },
          }}
          numberOfLines={1}
          enablePoweredByContainer={false}
          isRowScrollable={false}
          fetchDetails
        />
      </View>
    </>
  );
};

const themedStyles = StyleService.create({
  inputContainer: {
    aligSelf: 'stretch',
    alignItems: 'center',
    minHeight: 48,
    marginTop: 8,
    marginBottom: -4,
  },
  gradientInputContainer: {
    flex: 1,
    borderRadius: 2,
  },
  gradient: {
    height: 48,
    width: '100%',
    marginTop: 0,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  textWithOpacity: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    alignSelf: 'flex-start',
    opacity: 0.5,
  },
  label: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 4,
    paddingTop: 24,
  },
  emptyLabel: {
    paddingTop: 16,
  },
  error: {
    fontSize: 12,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'orange-03',
    textTransform: 'lowercase',
    textAlign: 'right',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  textInputFocus: {
    fontSize: 16,
    fontFamily: 'SourceSansPro_400Regular',
    paddingHorizontal: 20,
    borderColor: 'transparent',
    backgroundColor: 'brand-field-focused-background',
    color: 'text-primary',
    minHeight: 48,
    borderRadius: 2,
    borderWidth: 1,
    paddingVertical: 8,
  },
  textInputBlur: {
    fontSize: 16,
    fontFamily: 'SourceSansPro_400Regular',
    paddingHorizontal: 20,
    borderColor: 'gray-02',
    backgroundColor: 'brand-field-background',
    color: 'text-primary',
    minHeight: 48,
    borderRadius: 2,
    borderWidth: 1,
    paddingVertical: 8,
  },
  container: {
    alignSelf: 'stretch',
  },
  textInputContainer: {
    margin: 1,
  },
  addressRow: {
    fontSize: 16,
    minHeight: 48,
    flexDirection: 'row',
    backgroundColor: 'brand-field-focused-background',
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  description: {
    flex: 1,
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'text-primary',
  },
  listView: {
    borderWidth: 1,
    borderColor: 'gray-02',
    borderRadius: 2,
    marginTop: -6,
  },
});

export default AddressAutoComplete;
