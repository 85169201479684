import { Layout, useStyleSheet, StyleService } from "@ui-kitten/components";
import React, { forwardRef, Ref } from "react";
import {
  StyleProp,
  ViewStyle,
  ImageBackground,
  ScrollView,
  LayoutChangeEvent,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import Header from "../Header";
import ConditionalWrapper from "./ConditionalWrapper";
import MainContainer from "./MainContainer";
import { Menu } from "./MenuPopup";
import { useMediaQuery } from "react-responsive";

type Props = {
  showHeader?: boolean;
  scrollEnabled?: boolean;
  isChildStack?: boolean;
  onGoBack?: () => void;
  title?: string;
  titleStyle?: StyleProp<ViewStyle>;
  headerLeftButtonIcon?: string | React.ReactNode;
  headerLeftButtonColor?: string;
  headerLeftButtonSize?: number;
  headerLeftButtonIconTestID?: string;
  headerRightButtonIconTestID?: string;
  scrollViewTestID?: string;
  headerLeftButtonOnPress?: () => void;
  headerLeftButtonMenu?: Menu[];
  headerRightButtonIcon?: string | React.ReactNode;
  headerRightButtonColor?: string;
  headerRightButtonSize?: number;
  headerRightButtonOnPress?: () => void;
  headerRightButtonMenu?: Menu[];
  headerOnLayout?: (e: LayoutChangeEvent) => void;
  scrollStyle?: StyleProp<ViewStyle>;
  showLogo?: boolean;
  backgroundImage?: any;
  gradientVisible?: boolean;
  children?: React.ReactNode;
  toast?: React.ReactNode;
  headerStyle?: StyleProp<ViewStyle>;
};

const SecondaryContainerView = forwardRef<ScrollView, Props>(
  (props: Props, ref?: Ref<ScrollView>) => {
    const {
      showHeader = true,
      scrollEnabled = false,
      isChildStack,
      onGoBack,
      headerLeftButtonIcon,
      headerLeftButtonColor,
      headerLeftButtonSize,
      headerLeftButtonOnPress,
      headerLeftButtonMenu,
      headerRightButtonIcon,
      headerRightButtonColor,
      headerRightButtonSize,
      headerRightButtonOnPress,
      headerRightButtonMenu,
      headerOnLayout,
      title,
      showLogo,
      scrollStyle,
      gradientVisible = true,
      children,
      headerLeftButtonIconTestID,
      scrollViewTestID,
      toast,
      headerStyle,
    } = props;

    const styles = useStyleSheet(themedStyles);
    const insets = useSafeAreaInsets();
    const isMobile = useMediaQuery({ maxWidth: 700 });
    return (
      <Layout style={styles.container}>
        <ImageBackground
          source={require("../../assets/images/background.png")}
          style={isMobile ? styles.imageBgMobile : styles.imageBg}
        >
          {showHeader && (
            <Header
              isChildStack={isChildStack}
              onGoBack={onGoBack}
              leftButtonIcon={headerLeftButtonIcon}
              leftButtonColor={headerLeftButtonColor}
              leftButtonSize={headerLeftButtonSize}
              leftButtonOnPress={headerLeftButtonOnPress}
              leftButtonMenu={headerLeftButtonMenu}
              leftButtonTestId={headerLeftButtonIconTestID}
              rightButtonIcon={headerRightButtonIcon}
              rightButtonColor={headerRightButtonColor}
              rightButtonSize={headerRightButtonSize}
              rightButtonOnPress={headerRightButtonOnPress}
              rightButtonMenu={headerRightButtonMenu}
              onLayout={headerOnLayout}
              title={title}
              showLogo={showLogo}
              style={headerStyle}
            />
          )}
          <ConditionalWrapper
            condition={gradientVisible}
            wrapper={(children: any) => (
              <MainContainer>{children}</MainContainer>
            )}
          >
            <ConditionalWrapper
              condition={scrollEnabled}
              wrapper={(children: any) => (
                <ScrollView
                  ref={ref}
                  style={styles.container}
                  contentContainerStyle={[
                    styles.scroll,
                    { paddingBottom: insets.bottom + 32 },
                    scrollStyle,
                  ]}
                  keyboardShouldPersistTaps="handled"
                  testID={scrollViewTestID}
                  keyboardDismissMode="on-drag"
                >
                  {children}
                </ScrollView>
              )}
            >
              {children}
            </ConditionalWrapper>
          </ConditionalWrapper>
        </ImageBackground>
        {toast}
      </Layout>
    );
  }
);

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  imageBg: {
    flex: 1,
    resizeMode: "cover",
    alignItems: "center",
  },
  imageBgMobile: {
    flex: 1,
    resizeMode: "cover",
  },
  scroll: {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingTop: 16,
  },
});

export default SecondaryContainerView;
