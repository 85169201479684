export enum DocumentStatus {
  REQUIRED = 'Required',
  RECEIVED_BACK = 'ReceivedBack',
  RECEIVED_FRONT = 'ReceivedFront',
  INVALID = 'Invalid',
  APPROVED = 'Approved',
  PENDING_REVIEW = 'PendingReview',
}

class _UnitDocumentStatus {
  constructor() {
    this.list = this.keys.map((key) => ({
      key,
      value: this.getStatusTitle(key),
    }));
  }

  list: { key: DocumentStatus; value: string }[];
  keys = [
    DocumentStatus.REQUIRED,
    DocumentStatus.RECEIVED_BACK,
    DocumentStatus.RECEIVED_FRONT,
    DocumentStatus.INVALID,
    DocumentStatus.APPROVED,
    DocumentStatus.PENDING_REVIEW,
  ];
  titleMapping = {
    [DocumentStatus.REQUIRED]: 'Missing Document',
    [DocumentStatus.RECEIVED_BACK]: 'Missing Document Front-side',
    [DocumentStatus.RECEIVED_FRONT]: 'Missing Document Back-side',
    [DocumentStatus.INVALID]: 'Rejected',
    [DocumentStatus.APPROVED]: 'Approved',
    [DocumentStatus.PENDING_REVIEW]: 'Awaiting Review',
  };

  getStatusTitle = (status: DocumentStatus): string => {
    return this.titleMapping[status];
  };
}
export const UnitDocumentStatus = new _UnitDocumentStatus();
