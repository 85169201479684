import {
  Text,
  useStyleSheet,
  StyleService,
  Button,
  Spinner,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  Platform,
  View,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';

import ContainerScrollView from '../../components/Common/ContainerScrollView';
import OtpInput, { OtpInputHandle } from '../../components/Common/OtpInput';
import { useStore } from '../../stores';
import { trackWithProperties } from '../../utils/analytics';

type Props = {
  onNext: () => void;
  isFocus: boolean;
  phoneNumber: string;
  setPhoneId: (id: string) => void;
  selectedIndex: number;
};

const LoadingIndicator = () => <Spinner status="basic" size="small" />;

const VerifyPhoneNumber: React.FC<Props> = ({
  onNext,
  isFocus,
  phoneNumber,
  setPhoneId,
  selectedIndex
}) => {
  const styles = useStyleSheet(themedStyles);
  const { userStore } = useStore();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const verifyCodeRef = useRef<OtpInputHandle>(null);

  const codeLength = 6;
  const cellSpacing = 8;
  const { width } = useWindowDimensions();
  // const cellSize = Math.floor((width - (cellSpacing + 64)) / 6);
  const cellSize = 48

  const callingCode = '+1';
  const phoneNumberWithCallingCode = `${callingCode}${phoneNumber}`;
  const phone = Array.from(userStore.phoneNumbers.values()).find(
    (value) => value.number === phoneNumberWithCallingCode,
  );

  const requestVerificationCode = async () => {
    trackWithProperties('Verification Initiated', {
      Phone: phone?.number ?? phoneNumberWithCallingCode,
      Platform: Platform.OS,
    });
    await userStore.sendVerificationCode(
      phone?.number ?? phoneNumberWithCallingCode,
    );
  };

  useEffect(() => {
    if (isFocus && verifyCodeRef && verifyCodeRef.current) {
      verifyCodeRef.current.focus();
    }
  }, [isFocus]);

  useEffect(() => {
    const isPhoneNew = Array.from(userStore.phoneNumbers.values()).find(
      (value) => value.number === phoneNumberWithCallingCode,
    );
    if (isPhoneNew && isFocus) {
      setPhoneId(`${phone ? phone.id : ''}`);
    }
  }, [phoneNumber, loading, isFocus]);

  useEffect(() => {
    (async () => {
      if (isFocus) {
        if (phone && phone?.isVerified) {
          if (!phone.isUnitPhoneNumber) {
            await userStore.updatePhoneNumber(phone.id, {
              isUnitPhoneNumber: true,
            } as any);
          }
          onNext();
        } else {
          requestVerificationCode();
        }
      }
    })();
  }, [phone, phoneNumber, isFocus]);

  const onSubmit = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    let result;
    if (phone) {
      result = await userStore.updatePhoneNumber(phone.id, {
        isVerified: true,
        isUnitPhoneNumber: true,
        token: value,
      } as any);
    } else {
      const newPhoneData = {
        title: 'Ugami Card Application Phone Number',
        number: phoneNumberWithCallingCode,
        isPrimary: true,
        isVerified: true,
        isUnitPhoneNumber: true,
        token: value,
      };
      result = await userStore.addPhoneNumber(newPhoneData as any);
    }
    trackWithProperties('Verification Submitted', {
      // 'Acquisition channel': '',
      Phone: phone?.number ?? phoneNumberWithCallingCode,
      Platform: Platform.OS,
      'Verification successful': `${result.ok}`,
    });
    if (result) {
      if (!result.ok) {
        if (verifyCodeRef && verifyCodeRef.current) {
          verifyCodeRef.current.shake();
        }
      } else {
        if (phone) {
          setPhoneId(`${phone.id}`);
        } else {
          setPhoneId(result.extra!.id);
        }
      }
    }
    setLoading(false);
    onNext()
  }, [phone, loading, phoneNumberWithCallingCode, onNext, value]);

  if (phone && phone?.isVerified) {
    return <View />;
  }

  return (
    <ContainerScrollView testID="VerifyPhoneNumberScrollView">
      <Text style={styles.header}>Verify Phone Number</Text>
      <View style={styles.form}>
        <Text style={styles.body}>
          We’ve sent a text message with a 6 digit verification code to:{' '}
          <Text style={styles.bold}>
            {callingCode}-
            {phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
          </Text>
        </Text>
        <View style={styles.cellsContainer}>
          <OtpInput
            ref={verifyCodeRef}
            code={value}
            setCode={setValue}
            cellSize={cellSize}
            codeLength={codeLength}
            cellSpacing={cellSpacing}
            autoFocus={isFocus}
            testID="SmoothPinCodeInput"
            selectedIndex={selectedIndex}
          />
        </View>
        <TouchableOpacity onPress={requestVerificationCode}>
          <Text style={styles.resend}>Resend Verification Code</Text>
        </TouchableOpacity>
        <View style={styles.column} />
        <Button
          size="large"
          onPress={onSubmit}
          accessoryLeft={loading ? LoadingIndicator : undefined}
          disabled={loading}
          testID="VerifyButton"
        >
          VERIFY
        </Button>
      </View>
    </ContainerScrollView>
  );
};

const themedStyles = StyleService.create({
  column: {
    flex: 1,
  },
  form: {
    flex: 1,
    marginTop: 12,
  },
  body: {
    fontSize: 14,
    color: 'text-primary',
    fontFamily: 'SourceSansPro_400Regular',
    marginVertical: 8,
  },
  bold: {
    fontSize: 14,
    color: 'text-primary',
    fontFamily: 'SourceSansPro_600SemiBold',
  },
  header: {
    fontSize: 23,
    lineHeight: 32,
    fontFamily: 'Jura_700Bold',
    color: 'text-primary',
    textAlign: 'left',
  },
  imageBg: {
    flex: 1,
  },
  scrollContainer: {
    flex: 1,
  },
  cellStyle: {
    borderColor: '#616161',
    borderWidth: 1,
  },
  cellStyleFocused: {
    borderColor: 'red-03',
    borderWidth: 1,
  },
  textStyle: {
    color: '#FFFFFF',
  },
  cellsContainer: {
    alignSelf: 'stretch',
  },
  resend: {
    fontSize: 14,
    color: 'red-02',
    fontFamily: 'SourceSansPro_700Bold',
    paddingVertical: 8,
    marginTop: 16,
  },
});

export default observer(VerifyPhoneNumber);
