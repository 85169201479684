import { useStyleSheet, StyleService } from '@ui-kitten/components';
import React from 'react';
import { View, Image, TouchableOpacity, Platform, Linking } from 'react-native';

type Props = {
    device: string
};
const PlayStore: React.FC<Props> = ({device}) => {
    const styles = useStyleSheet(themedStyles);

    const pressStore = (name) => {
        switch (Platform.OS) {
            case 'web':
                window.open(name === 'android' ? 'https://play.google.com/store/apps/details?id=com.ugami.app&hl=es_VE&gl=US' : 'https://apps.apple.com/us/app/ugami-debit-card-for-gamers/id1589150330', '_blank');
                break
            case 'android':
                Linking.openURL(name === 'android' ? "https://play.google.com/store/apps/details?id=com.ugami.app&hl=es_VE&gl=US" : 'https://apps.apple.com/us/app/ugami-debit-card-for-gamers/id1589150330');
                break
            case 'ios':
                Linking.openURL(name === 'android' ? "https://play.google.com/store/apps/details?id=com.ugami.app&hl=es_VE&gl=US" : 'https://apps.apple.com/us/app/ugami-debit-card-for-gamers/id1589150330');
                break
            default:
                window.open(name === 'android' ? 'https://play.google.com/store/apps/details?id=com.ugami.app&hl=es_VE&gl=US' : 'https://apps.apple.com/us/app/ugami-debit-card-for-gamers/id1589150330', '_blank');
                break
        }
    }

    return (
        <View style={device === 'mobil' ? styles.playStoreBoxMobile : styles.playStoreBoxDesktop}>
            <TouchableOpacity onPress={() => pressStore('ios')}>
                <Image
                    style={styles.playStoreImage}
                    source={{
                        uri: 'https://i.stack.imgur.com/xHgSL.png',
                    }}
                />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => pressStore('android')}>
                <Image
                    style={styles.playStoreImage}
                    source={{
                        uri: 'https://greatscenicrailways.co.uk/wp-content/uploads/2019/04/get-it-on-google-play-store-button.png',
                    }}
                />
            </TouchableOpacity>
        </View>
    );
};

const themedStyles = StyleService.create({
    playStoreBoxDesktop: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 300,
    },
    playStoreBoxMobile: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        height: 100,
    },
    playStoreImage: {
        width: 135,
        height: 40,
    },
});

export default PlayStore;