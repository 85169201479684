import { useStyleSheet, StyleService, Text } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import {
  ImageBackground,
  ImageStyle,
  StyleProp,
  useWindowDimensions,
  View,
  TouchableOpacity,
} from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  runOnJS,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  message: string;
  success?: boolean;
  toastDuration?: number;
  rightButtonIcon?: React.ReactNode;
  onPress?: () => void;
};

const EnhancedToast: React.FC<Props> = ({
  visible,
  setVisible,
  message,
  success = true,
  toastDuration = 8, //seconds
  rightButtonIcon,
  onPress = undefined,
}) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const screenWidth = useWindowDimensions().width;
  const screenHeight = useWindowDimensions().height;
  const width = screenWidth - 32;
  const [duration, setDuration] = useState(toastDuration);
  const defaultTransitionTime = 3000;

  const translateY = useSharedValue(-screenHeight);
  const opacity = useSharedValue(0);

  const onToastPress = () => {
    !!onPress && onPress();
    translateY.value = withTiming(
      -screenWidth,
      {
        duration: defaultTransitionTime,
      },
      (finished?: boolean) => {
        if (finished) {
          runOnJS(closeToast)();
        }
      },
    );
  };
  useEffect(() => {
    if (visible) {
      opacity.value = withTiming(1, {
        duration: defaultTransitionTime,
      });
      translateY.value = withTiming(0, {
        duration: defaultTransitionTime,
      });
    }
  }, [visible]);

  useEffect(() => {
    let interval: any;
    if (visible) {
      interval = setInterval(() => {
        if (duration === 0) {
          opacity.value = withTiming(0, {
            duration: defaultTransitionTime / 2,
          });
          translateY.value = withTiming(
            -screenWidth,
            {
              duration: defaultTransitionTime,
            },
            (finished?: boolean) => {
              if (finished) {
                runOnJS(closeToast)();
              }
            },
          );
        } else {
          setDuration(duration - 1);
        }
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [duration, visible]);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateY: translateY.value }],
    opacity: opacity.value,
  }));

  const closeToast = () => {
    setVisible(false);
    translateY.value = -screenWidth;
  };

  if (!visible) {
    return null;
  }

  return (
    <Animated.View
      style={[styles.container, { width, top: insets.top + 20 }, animatedStyle]}
    >
      <TouchableOpacity onPress={onToastPress}>
        <ImageBackground
          source={require('../../assets/images/enhanced-toast.png')}
          style={[
            styles.imageBg,
            { width: width - insets.left - insets.right },
          ]}
          imageStyle={[{ width }, styles.image as StyleProp<ImageStyle>]}
        >
          <View style={styles.toastContent}>
            <View style={styles.spacer} />
            <Text style={[styles.message]}>{message}</Text>
            {rightButtonIcon && rightButtonIcon}
            <View style={styles.spacer} />
          </View>
        </ImageBackground>
      </TouchableOpacity>
    </Animated.View>
  );
};

const themedStyles = StyleService.create({
  container: {
    position: 'absolute',
    left: 16,
  },
  toastContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 7,
    alignSelf: 'center',
    marginHorizontal: 40,
  },
  message: {
    fontFamily: 'SourceSansPro_400Regular',
    fontSize: 14,
    textAlign: 'left',
    marginRight: 8,
  },
  imageBg: {
    height: 80,
    marginTop: 2,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  image: {
    height: 110,
    resizeMode: 'cover',
  },
  spacer: {
    width: 40,
  },
});

export default EnhancedToast;
