import {
  useStyleSheet,
  StyleService,
  Text,
  Button,
  Input,
} from '@ui-kitten/components';
import { differenceInYears, subYears } from 'date-fns';
import { isUndefined } from 'lodash';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { View, TouchableOpacity, Keyboard, Platform } from 'react-native';

import TooltipInfoVisible from '../TooltipInfoVisible/index';
import PasswordStrengthChecker from '../../components/PasswordStrengthChecker';
import ContainerScrollView from '../Common/ContainerScrollView';
import DropDownMenu from '../Common/DropDownMenu';
import CheckBox from '../Common/CheckBox';
import ErrorMessage from '../Common/ErrorMessage';
import LabeledInput from '../Common/LabeledInput';
import ArrowIcon from '../Common/Icons/ArrowIcon';
import LoadingIndicator from '../Common/LoadingIndicator'
import { BAD_WORDS } from '../../constants/BadWords';
import { PasswordStrength } from '../../constants/PasswordStrength';
import { FormData } from '../../containers/SignUp';
import { MIN_AGE } from '../../constants/minAge'
import { GENDER } from '../../constants/gender';
import { useAutoScroll } from '../../hooks';
import { useStore } from '../../stores';
import { Props } from '../../types/AccountInfo';
import regexEmail from '../../utils/regexEmail'
import { calculateAge } from '../../utils/age';
import {
  identifyOnSignUp,
  track,
  trackWithProperties,
} from '../../utils/analytics';
import { getErrors } from '../../utils/errors';
import { isNumeric } from '../../utils/isNumeric';
import { onGoToPrivacyPolicy, onGoToTermsOfUse } from '../../utils/externalLink';

const AccountInfo: React.FC<Props> = ({ onNext, setAccountInfo }) => {
  const styles = useStyleSheet(themedStyles);
  const [agree, setAgree] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(
    PasswordStrength.NONE
  );
  const [loading, setLoading] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const emailRef = useRef<Input>(null);
  const firstNameRef = useRef<Input>(null);
  const lastNameRef = useRef<Input>(null);
  const birthDayRef = useRef<Input>(null);
  const gamertagRef = useRef<Input>(null);
  const password1Ref = useRef<Input>(null);
  const password2Ref = useRef<Input>(null);
  const store = useStore();
  const { authStore } = store;
  const { scrollRef, ScrollTracker, scrollToField, scrollToTop } =
    useAutoScroll();
  const tooltipInfoText =
    "Your Gamertag is your publicly visible username for Ugami. Feel free to choose whatever you like.";
  const {
    register,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    register("email", {
      required: true,
      pattern: {
        value: regexEmail,
        message: 'invalid email',
      },
    });
    register("firstName", { required: true });
    register("lastName", { required: true });
    register("dateOfBirth", { required: true });
    register("gender", { required: false });
    register("gamerTag", { required: true });
    register("password1", { required: true });
    register("password2", {
      required: true,
    });
    register("referrerCode", {
      required: false,
    });
    setValue("dateOfBirth", subYears(new Date(), MIN_AGE));
  }, [register]);

  const onChangeEmail = (email: string) => {
    if (regexEmail.test(email)) {
      clearErrors('email');
    }
    setValue("email", email.trim());
  };

  const onChangePassword1 = (password1: string) => {
    if (password1 === "") {
      clearErrors("password1");
      clearErrors("password2");
    } else if (passwordStrength !== PasswordStrength.STRONG) {
      setError("password1", {
        message: "password is too weak",
      });
    } else {
      clearErrors("password1");
    }
    setValue("password1", password1);
  };

  useEffect(() => {
    setValue("referrerCode", store.referrerCode);
  }, [store.referrerCode]);

  const onSubmitWrapped = handleSubmit(async (data: FormData) => {
    if (loading) {
      return;
    }
    clearErrors();
    if (data.password1 !== data.password2) {
      setError("password1", { message: "" });
      setError("password2", { message: "passwords must match" });
      return;
    }
    setLoading(true);
    setErrorMessage("");
    const result = await authStore.userExists(data.email);
    setLoading(false);
    if (!result.ok) {
      setErrorMessage(getErrors(result.errors));
      scrollToTop();
    } else {
      if (!result.extra?.exist) {
        setAccountInfo(data);
        const age = `${calculateAge(data.dateOfBirth)}` || "";
        const name = `${data.firstName} ${data.lastName}`;
        identifyOnSignUp(data.email, {
          email: data.email,
          name,
          sign_up_completed: false,
        });
        trackWithProperties("Create Your Account - Step 1 - Completed", {
          Name: name,
          Email: data.email,
          Age: age,
          Platform: Platform.OS,
        });
        if (data.referrerCode) {
          trackWithProperties("Proceeded With Referral Code", {
            code: data.referrerCode,
          });
        } else {
          track("Proceeded Without Referral Code");
        }
        Keyboard.dismiss();
        onNext();
      } else {
        setError("email", { message: "account already exists" });
        onEmailError();
      }
    }
  });

  const onEmailError = useCallback(() => {
    scrollToField("email");
    emailRef.current?.focus();
  }, []);

  const onSubmitEditingEmail = useCallback(() => {
    firstNameRef.current?.focus();
  }, []);

  const onSubmitEditingFirstName = useCallback(() => {
    lastNameRef.current?.focus();
  }, []);

  const onSubmitEditingLastName = useCallback(() => {
    gamertagRef.current?.focus();
  }, []);

  const onSubmitEditingPassword1 = useCallback(() => {
    password2Ref.current?.focus();
  }, []);

  const onCalendarFocus = useCallback(() => {
    Keyboard.dismiss();
  }, []);

  const onChangeBirthDate = (dateOfBirth: Date) => {
    clearErrors("dateOfBirth");
    if (dateOfBirth) {
      const age = differenceInYears(new Date(), dateOfBirth);
      if (age < MIN_AGE) {
        setError("dateOfBirth", {
          message: "you must be at least 13 to apply",
        });
      }
    }
    setValue("dateOfBirth", dateOfBirth);
    setIsDateSelected(true);
  };

  const onPassword2Blur = () => {
    const password1 = watch("password1");
    const password2 = watch("password2");
    if (password1 === "" || password2 === "") {
      clearErrors("password2");
      if (password2 === "" && errors.password1?.message === "") {
        clearErrors("password1");
      }
      return;
    }
    if (password1 !== password2) {
      setError("password1", {
        message:
          password1.length >= 8 ? "" : "password must be at least 8 characters",
      });
      setError("password2", { message: "passwords must match" });
    } else {
      clearErrors("password1");
      clearErrors("password2");
    }
  };

  const onPassword1Blur = () => {
    const password1 = watch("password1");
    const password2 = watch("password2");
    if (password1 === "") {
      clearErrors("password1");
      clearErrors("password2");
      return;
    }
    if (password1.length < 8) {
      setError("password1", {
        message: "password must be at least 8 characters",
      });
    } else if (passwordStrength !== PasswordStrength.STRONG) {
      setError("password1", {
        message: "password is too weak",
      });
    } else if (password2 && password2 !== "") {
      onPassword2Blur();
    } else {
      clearErrors("password1");
    }
  };

  const onGamertagChange = (text: string) => {
    const newText = text.replace(/[^a-z0-9]/gi, "");
    const isGamertagInvalid = BAD_WORDS.some((word) =>
      newText.toLowerCase().includes(word.toLowerCase())
    );
    if (newText !== "" && newText.length < 6) {
      setError("gamerTag", {
        message: "minimum of 6 characters",
      });
    } else if (isGamertagInvalid) {
      setError("gamerTag", {
        message: "invalid gamertag",
      });
    } else if (newText !== "" && newText.length >= 6 && isNumeric(newText)) {
      setError("gamerTag", {
        message: "gamertag must be alphanumeric",
      });
    } else {
      clearErrors("gamerTag");
    }
    setValue("gamerTag", newText);
  };

  const disabled =
    !watch("email") ||
    !watch("firstName") ||
    !watch("lastName") ||
    !watch("gamerTag") ||
    !watch("dateOfBirth") ||
    !watch("password1") ||
    !watch("password2") ||
    watch("email").trim() === "" ||
    watch("firstName").trim() === "" ||
    watch("lastName").trim() === "" ||
    watch("gamerTag").trim() === "" ||
    differenceInYears(new Date(), watch("dateOfBirth")) < MIN_AGE ||
    watch("password1") === "" ||
    watch("password2") === "" ||
    watch("password1").length < 8 ||
    watch("password1") !== watch("password2") ||
    !agree ||
    watch("gamerTag").length < 6 ||
    passwordStrength !== PasswordStrength.STRONG ||
    !isUndefined(errors.gamerTag) ||
    !isDateSelected;

  return (
    <ContainerScrollView ref={scrollRef} testID="AccountInformationScrollView">
      <ErrorMessage errorMessage={errorMessage} />
      <Text style={styles.title}>Account Information</Text>
      <View style={styles.inputContainer}>
        <ScrollTracker field="email" />
        <LabeledInput
          ref={emailRef}
          label="Email"
          placeholder="gamer@website.com"
          keyboardType="email-address"
          autoCapitalize="none"
          autoCorrect={false}
          returnKeyType="next"
          onChangeText={onChangeEmail}
          onSubmitEditing={onSubmitEditingEmail}
          error={errors.email}
          testID="SignUpEmailInput"
        />
        <View style={styles.name}>
          <View style={styles.firstName}>
            <LabeledInput
              ref={firstNameRef}
              label="First Name"
              placeholder="First Name"
              autoCapitalize="words"
              autoCorrect={false}
              returnKeyType="next"
              error={errors.firstName}
              onChangeText={(value) => setValue("firstName", value)}
              onSubmitEditing={onSubmitEditingFirstName}
              testID="SignUpFirstNameInput"
            />
          </View>
          <View style={styles.lastName}>
            <LabeledInput
              ref={lastNameRef}
              label="Last Name"
              placeholder="Last Name"
              autoCapitalize="words"
              autoCorrect={false}
              error={errors.lastName}
              onChangeText={(value) => setValue("lastName", value)}
              returnKeyType="go"
              onSubmitEditing={onSubmitEditingLastName}
              testID="SignUpLastNameInput"
            />
          </View>
        </View>
        <LabeledInput
          ref={gamertagRef}
          label="Gamertag"
          placeholder="awesomegamer"
          autoCapitalize="none"
          autoCorrect={false}
          returnKeyType="next"
          value={watch("gamerTag")}
          onChangeText={onGamertagChange}
          accessoryRight={() => <TooltipInfoVisible text={tooltipInfoText} touchableOpacityStyles={"gamertag"} />}
          error={errors.gamerTag}
          testID="SignUpGamerTagInput"
          maxLength={21}
        />
        <LabeledInput
          // @ts-ignore
          ref={birthDayRef}
          label="Date Of Birth"
          placeholder="11/13/1997"
          returnKeyType="next"
          onSelect={onChangeBirthDate}
          onFocus={onCalendarFocus}
          date={watch("dateOfBirth")}
          min={new Date("1900-01-01")}
          max={new Date()}
          error={errors.dateOfBirth}
          isDate
          testID="SignUpDateOfBirthInput"
        />
        <DropDownMenu
          label="Gender"
          placeholder="Gender you identify as"
          data={GENDER}
          value={watch("gender") || ""}
          onSelect={(value) => setValue("gender", value)}
        />
        <LabeledInput
          ref={password1Ref}
          label="Password"
          placeholder="supersecurepassword"
          autoCapitalize="none"
          autoCorrect={false}
          onBlur={onPassword1Blur}
          secureTextEntry
          returnKeyType="next"
          onChangeText={onChangePassword1}
          error={errors.password1}
          onSubmitEditing={onSubmitEditingPassword1}
          testID="SignUpPassword1Input"
        />
        <LabeledInput
          ref={password2Ref}
          label="Confirm Password"
          placeholder="supersecurepassword"
          autoCapitalize="none"
          autoCorrect={false}
          onBlur={onPassword2Blur}
          secureTextEntry
          onChangeText={(value) => setValue("password2", value)}
          error={errors.password2}
          testID="SignUpPassword2Input"
        />
        <PasswordStrengthChecker
          password={watch("password1") || ""}
          passwordStrength={passwordStrength}
          setPasswordStrength={setPasswordStrength}
        />
        <View style={styles.checkboxRow}>
          <CheckBox
            status="basic"
            checked={agree}
            onChange={(nextChecked) => setAgree(nextChecked)}
            testID="SignUpAgreeCheckBox"
          />
          <View style={styles.textWithLink}>
            <Text style={styles.normal}>
              By checking this box, you agree to our{" "}
              <TouchableOpacity
                style={[Platform.OS === "android" ? { height: 15 } : {}]}
                onPress={onGoToTermsOfUse}
              >
                <Text style={styles.redText}>Terms of Service</Text>
              </TouchableOpacity>{" "}
              and{" "}
              <TouchableOpacity
                style={[Platform.OS === "android" ? { height: 15 } : {}]}
                onPress={onGoToPrivacyPolicy}
              >
                <Text style={styles.redText}>Privacy Policy</Text>
              </TouchableOpacity>
              . You also confirm that you are 13 years or older.
            </Text>
          </View>
        </View>
      </View>
      <Button
        size="large"
        style={styles.next}
        accessoryRight={<ArrowIcon />}
        onPress={onSubmitWrapped}
        accessoryLeft={loading ? LoadingIndicator : undefined}
        disabled={disabled}
        testID="SignUpAccountInfoNextButton"
      >
        NEXT
      </Button>
    </ContainerScrollView>
  );
};

const themedStyles = StyleService.create({
  inputContainer: {
    alignSelf: "stretch",
  },
  title: {
    fontSize: 24,
    fontFamily: "Jura_600SemiBold",
    alignSelf: "flex-start",
    paddingTop: 8,
  },
  checkboxRow: {
    flexDirection: "row",
    paddingTop: 28,
    alignItems: "flex-start",
  },
  textWithLink: {
    flex: 1,
    paddingLeft: 20,
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
  },
  normal: {
    fontSize: 14,
    lineHeight: 24,
    fontFamily: "SourceSansPro_400Regular",
  },
  redText: {
    fontSize: 14,
    fontFamily: "SourceSansPro_700Bold",
    color: "red-03",
  },
  next: {
    marginTop: 36,
    marginBottom: 24,
    alignSelf: "flex-end",
    paddingHorizontal: 36,
  },
  gamertag: {
    marginTop: 8,
    marginLeft: 16,
  },
  name: {
    alignSelf: "stretch",
    flexDirection: "row",
  },
  firstName: {
    flex: 1,
    marginRight: 8,
  },
  lastName: {
    flex: 1,
    marginLeft: 8,
  },
});

export default AccountInfo;
