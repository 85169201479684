import { Platform } from "react-native";
import React from 'react'

const SvgIcon = ({ children }: any) => {
    if (Platform.OS === 'web') {
        return <img src={children.type} {...children.props} />;
    }
    return children;
};

export default SvgIcon;