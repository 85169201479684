import { useNavigation, RouteProp } from '@react-navigation/native';
import { Spinner } from '@ui-kitten/components';
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { View, BackHandler } from 'react-native';
import { WebView } from 'react-native-webview';

import SecondaryContainerView from '../../components/Common/SecondaryContainerView';
import { AppStackParamList, AppStackNavigation } from '../../navigation';

type Props = { route: RouteProp<AppStackParamList, 'External Link'> };

const ExternalLink: React.FC<Props> = (props) => {
  const ref = useRef<WebView>(null);
  const [canGoBack, setCanGoBack] = useState(Boolean);
  const navigation = useNavigation<AppStackNavigation>();

  const renderLoading = useCallback(
    () => (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          paddingBottom: 100,
        }}
      >
        <Spinner size="medium" status="control" />
      </View>
    ),
    [],
  );

  const handleBackButtonPress = () => {
    if (ref.current && canGoBack) {
      ref.current.goBack();
    } else {
      navigation.goBack();
    }
  };

  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      () => {
        handleBackButtonPress();
        return true;
      },
    );
    return () => {
      backHandler.remove();
    };
  }, [canGoBack]);

  return (
    <SecondaryContainerView
      gradientVisible={false}
      title={props.route.params.title}
    >
      <WebView
        source={{ uri: props.route.params.url }}
        style={{
          flex: 1,
          backgroundColor: 'transparent',
          marginTop: 5,
        }}
        ref={ref}
        renderLoading={renderLoading}
        startInLoadingState
        onNavigationStateChange={(navState) => {
          setCanGoBack(navState.canGoBack);
        }}
      />
    </SecondaryContainerView>
  );
};

export default ExternalLink;
