import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import React from 'react';

import ConfirmEmail from '../../containers/ConfirmEmail';
import CreateAccountComplete from '../../containers/CreateAccountComplete';
import LearnMore from '../../containers/LearnMore';
import UpdateEmail from '../../containers/UpdateEmail';

export type PostRegistrationStackParamList = {
  ConfirmEmail: undefined;
  CreateAccountComplete: undefined;
  'Update Email': undefined;
  LearnMore: { isAccountCreated: boolean };
};

export type PostRegistrationStackNavigation =
  StackNavigationProp<PostRegistrationStackParamList>;

type Props = object;

const Stack = createStackNavigator<PostRegistrationStackParamList>();

const PostRegistrationStack: React.FC<Props> = (props) => {
  return (
    <Stack.Navigator
      screenOptions={{
        cardStyle: { backgroundColor: 'transparent' },
        animationEnabled: false,
        headerShown: false,
        presentation: 'modal',
      }}
    >
      <Stack.Screen name="ConfirmEmail" component={ConfirmEmail} />
      <Stack.Screen
        name="CreateAccountComplete"
        component={CreateAccountComplete}
      />
      <Stack.Screen name="Update Email" component={UpdateEmail} />
      <Stack.Screen name="LearnMore" component={LearnMore} />
    </Stack.Navigator>
  );
};

export default PostRegistrationStack;
