/* eslint-disable no-console */

import camelcaseKeys from 'camelcase-keys';

import config from '../../config';

function offlineResponse() {
  console.log('[DEBUG: API] Something went wrong. Please try again later.');
  return 'Something went wrong. Please try again later.';
}

export type TokenType = string;
export type APIResponse = { response: { entities?: any } };

export function getFullUrl(endpoint: string): string {
  if (endpoint.indexOf('https://') >= 0 || endpoint.indexOf('http://') >= 0) {
    return endpoint;
  }
  return endpoint.indexOf(config.urls.api) === -1
    ? `${config.urls.api}${endpoint}`
    : endpoint;
}

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
export async function callApi(
  endpoint: string,
  method: string = 'GET',
  headers: HeadersInit = {},
  body: BodyInit = '',
): Promise<APIResponse> {
  const fullUrl = getFullUrl(endpoint);

  const requestHeaders = new Headers(headers);

  const params: RequestInit = {
    method,
    credentials: 'include',
    headers: requestHeaders,
  };

  if (
    method === 'PATCH' ||
    method === 'POST' ||
    method === 'PUT' ||
    method === 'DELETE'
  ) {
    if (body) {
      params.body = body;
    }
  }

  console.log('[DEBUG:API]', fullUrl, method, requestHeaders, body, params);

  const response = await fetch(fullUrl, params);
  if (response.status < 100 || response.status >= 500) {
    throw new Error(offlineResponse());
  }

  if (response.status === 204) {
    return { response: {} };
  }
  const json = camelcaseKeys(await response.json(), { deep: true });

  if (!response.ok) {
    throw new Error(JSON.stringify(json));
  }

  return {
    response: {
      entities: json,
    },
  };
}

export async function callApiWithToken(
  endpoint: string,
  token: TokenType,
  method = 'GET',
  headers: HeadersInit = {},
  body: BodyInit = '',
) {
  const headersWithAuthorization: HeadersInit = new Headers(headers);
  if (token) {
    headersWithAuthorization.append('Authorization', `Token ${token}`);
  }
  return callApi(endpoint, method, headersWithAuthorization, body);
}

export async function prepareFileForUpload(file: any) {
  if (file.uri?.startsWith('data:')) {
    const response = await fetch(file.uri);
    const blob = await response.blob();
    return new File([blob], file.name || 'file');
  }
  return file;
}
