import { useStyleSheet, StyleService } from "@ui-kitten/components";
import React, { useState, useCallback } from "react";
import { View } from "react-native";

import AvatarSelector from "../../components/Common/AvatarSelector";
import MainContainer from "../../components/Common/MainContainer";
import SecondaryContainerView from "../../components/Common/SecondaryContainerView";
import StepProgressBar from "../../components/Common/StepProgressBar";
import ViewPager from "../../components/Common/ViewPager";
import AccountInfo from "../../components/SignUp/AccountInfo";
import { convertPhoto, formatPhoto } from "../../utils/photos";
import { useStore } from "../../stores";
import { logEventAF, track } from "../../utils/analytics";
import { getErrors } from "../../utils/errors";
import { useMediaQuery } from "react-responsive";

type Props = object;

export type FormData = {
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  gender: string;
  gamerTag: string;
  password1: string;
  password2: string;
  referrerCode: string;
};

const SignUp: React.FC<Props> = (_props) => {
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const styles = useStyleSheet(themedStyles);
  const steps = 100;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  const [accountInfo, setAccountInfo] = useState<FormData>();
  const [photo, setPhoto] = useState<any>();
  const [avatar, setAvatar] = useState<{ name: string; image: number } | null>(
    null
  );
  const store = useStore();
  const { authStore, userStore, showToastMessage } = store;
  const onSelectPhoto = useCallback(async (image: any) => {
    const photo = await convertPhoto(formatPhoto(image));
    setPhoto(photo);
  }, []);

  const onNext = () => {
    setSelectedIndex(selectedIndex + 1);
  };

  const onGoBack = () => {
    if (loading) {
      return;
    }
    setSelectedIndex(selectedIndex - 1);
  };

  const createAccount = useCallback(async () => {
    if (loading || !accountInfo) {
      return;
    }
    setLoading(true);
    let result;

    if (!authStore.token || !authStore.user) {
      result = await authStore.signUp(accountInfo);
      if (!result.ok) {
        console.log("ERROR 1");
        showToastMessage(true, {
          message: getErrors(result.errors),
          error: true,
        });
        setLoading(false);
        return;
      }

      if (photo) {
        await userStore.updateMePhoto(photo);
      } else if (avatar) {
        await userStore.updateMePhotoUsingDefaultAvatar(avatar.name);
      }
    }
    
    store.setIsBiometricEnabled(false);
    store.setHasBiometricDetails(true);
    track("Created Account / Registration Completed");
    logEventAF("Completed Sign Up");
    store.setIsSignUpComplete(true);
  }, [loading, accountInfo, photo, avatar]);

  return (
    <SecondaryContainerView
      headerLeftButtonIconTestID="SignUpHeaderLeftButton"
      onGoBack={selectedIndex === 0 ? undefined : onGoBack}
      gradientVisible={false}
    >
      <View style={isMobile ? { flex: 1 } : styles.container}>
        <StepProgressBar
          steps={steps}
          maxTab={2}
          selectedIndex={selectedIndex}
        />
        <MainContainer>
          <ViewPager
            style={styles.pager}
            selectedIndex={selectedIndex}
            swipeEnabled={false}
          >
            <View style={styles.pager}>
              <AccountInfo onNext={onNext} setAccountInfo={setAccountInfo} />
            </View>
            <View style={styles.pager}>
              <AvatarSelector
                onNext={createAccount}
                photo={photo}
                setPhoto={setPhoto}
                avatar={avatar}
                setAvatar={setAvatar}
                onSelectPhoto={onSelectPhoto}
                buttonTitle="CREATE ACCOUNT"
                isSignUp
                loading={loading}
              />
            </View>
          </ViewPager>
        </MainContainer>
      </View>
    </SecondaryContainerView>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    overflow: "hidden",
    width: 575,
    background: "#292828",
    borderRadius: 5,
    marginBottom: 40,
    maxHeight: 1025,
  },
  containerMobile: {
    flex: 1,
    overflow: "hidden",
  },
  box: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  pager: {
    flex: 1,
    width: "100%",
  },
});

export default SignUp;
