export default {
    meta: {},
    appearances: {
        default: {
            mapping: {
                paddingHorizontal: 20,
                textMarginHorizontal: 0,
                textFontFamily: 'SourceSansPro_400Regular',
                placeholderFontFamily: 'SourceSansPro_400Regular',
                iconWidth: 24,
                iconHeight: 24,
                iconMarginHorizontal: 0,
                popoverBorderRadius: 2,
                popoverBorderColor: 'gray-02',
                popoverMaxHeight: 280,
            },
            variantGroups: {
                status: {
                    basic: {
                        borderColor: 'transparent',
                        backgroundColor: 'brand-field-background',
                        textColor: 'text-primary',
                        placeholderColor: 'text-placeholder',
                        iconTintColor: 'gray-01',
                        state: {
                            focused: {
                                borderColor: 'transparent',
                                backgroundColor: 'brand-field-background',
                            },
                            active: {
                                borderColor: 'transparent',
                                backgroundColor: 'brand-field-background',
                            },
                            disabled: {
                                borderColor: 'transparent',
                                backgroundColor: 'brand-field-background',
                                textColor: 'text-primary',
                                iconTintColor: 'gray-01',
                            },
                            hover: {
                                borderColor: 'gray-02',
                                backgroundColor: 'input-hover',
                            },
                        },
                    },
                },
                size: {
                    large: {
                        minHeight: 48,
                        borderRadius: 2,
                        borderWidth: 1,
                        paddingVertical: 8,
                        textFontSize: 16,
                        placeholderFontSize: 16,
                    },
                },
            },
        },
    },
}