import { useIsFocused, useNavigation } from '@react-navigation/native';
import {
  Text,
  useStyleSheet,
  StyleService,
  Button,
  Spinner,
} from '@ui-kitten/components';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Platform } from 'react-native';

import AddressInputs from '../../components/Address/AddressInputs';
import CheckBox from '../../components/Common/CheckBox';
import ContainerScrollView from '../../components/Common/ContainerScrollView';
import LabeledInput from '../../components/Common/LabeledInput';
import config from '../../config';
import { useAutoScroll } from '../../hooks';
import { AppStackNavigation } from '../../navigation';
import ErrorMessage from '../Common/ErrorMessage';
import { ContactInfoData } from './ContactInfo';
import { PersonalInfoData } from './PersonalInfo';

type Props = {
  onNext: () => void;
  onPersonalInfoEdit: () => void;
  onContactInfoEdit: () => void;
  personalInfo: PersonalInfoData;
  contactInfo: ContactInfoData;
  loading: boolean;
  errorMessage?: string;
  isShippingChanged: boolean;
};

const LoadingIndicator = (_props: any) => (
  <Spinner status="basic" size="small" />
);

const ConfirmInfo: React.FC<Props> = ({
  onNext,
  onPersonalInfoEdit,
  onContactInfoEdit,
  personalInfo,
  contactInfo,
  loading,
  errorMessage = '',
  isShippingChanged,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [check, setCheck] = useState(false);
  const { scrollRef, scrollToTop } = useAutoScroll();
  const isFocused = useIsFocused();
  const navigation = useNavigation<AppStackNavigation>();

  useEffect(() => {
    if (errorMessage && errorMessage !== '' && isFocused) {
      scrollToTop();
    }
  }, [errorMessage, isFocused]);

  const renderCallingCode = () => (
    <View style={styles.callingCodeContainer}>
      <Text style={styles.callingCode}>{`+${contactInfo.countryCode}`}</Text>
    </View>
  );

  const formatNumber = (number: string) => {
    number = number.replace(/[^\d]/g, '');
    if (number) {
      const length = number.length;
      if (length >= 4 && length < 7) {
        number = `${number.slice(0, 3)}-${number.slice(3)}`;
      } else if (length >= 7) {
        number = `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(
          6,
          10,
        )}`;
      }
    }
    return number;
  };

  const onGoToEsignConsent = () => {
    const url = `${config.ugamiSiteAddress}/app/electronic-disclosure-and-consent/`;
    navigation.navigate('External Link', {
      url,
      title: 'Electronic Disclosure and Consent',
    });
  };

  const onGoToConsumerDeposit = () => {
    const url = `${config.ugamiSiteAddress}/app/ugami-consumer-deposit-account-agreement/`;
    navigation.navigate('External Link', {
      url,
      title: 'Consumer Deposit Agreement',
    });
  };

  const onGoToDebitCardholder = () => {
    const url = `${config.ugamiSiteAddress}/app/ugami-visa-debit-cardholder-agreement/`;
    navigation.navigate('External Link', {
      url,
      title: 'Debit Cardholder Agreement',
    });
  };

  const onGoToRewards = () => {
    const url = `${config.ugamiSiteAddress}/app/ugami-rewards-terms-conditions/`;
    navigation.navigate('External Link', {
      url,
      title: 'Rewards Terms',
    });
  };

  const onGoToSweepstakes = () => {
    const url = `${config.ugamiSiteAddress}/app/ugami-ugipoints-sweepstakes/`;
    navigation.navigate('External Link', {
      url,
      title: 'Sweepstakes Rules',
    });
  };

  return (
    <ContainerScrollView ref={scrollRef} testID="ConfirmInfoScrollView">
      <View style={styles.headerContainer}>
        <Text style={styles.header}>Confirm Your Information</Text>
        <Text style={styles.headerSub}>
          Before submitting your application, please confirm that all your
          information is correct.
        </Text>
      </View>
      <ErrorMessage errorMessage={errorMessage} />
      <View style={styles.form}>
        <View style={styles.sectionHeaderRow}>
          <Text style={styles.sectionHeader}>Personal Information</Text>
          <TouchableOpacity
            onPress={onPersonalInfoEdit}
            testID="ConfirmInfoEditPersonalInfoButton"
          >
            <Text style={styles.sectionEdit}>Edit</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.row}>
          <View style={styles.columnFirstName}>
            <LabeledInput
              label="First Name"
              value={personalInfo.firstName}
              disabled
            />
          </View>
          <View style={styles.columnLastName}>
            <LabeledInput
              label="Last Name"
              value={personalInfo.lastName}
              disabled
            />
          </View>
        </View>
        <LabeledInput
          label="Date of Birth"
          value={format(personalInfo.birthday, 'MM/dd/yyyy')}
          disabled
        />
        <LabeledInput
          label="Social Security Number"
          value={`•••-••-${personalInfo.securityNumber.substring(
            personalInfo.securityNumber.length - 4,
            personalInfo.securityNumber.length,
          )}`}
          disabled
        />
        <LabeledInput
          label="Country Of Residence"
          value="United States"
          disabled
        />
      </View>
      <View style={styles.form}>
        <View style={styles.sectionHeaderRow}>
          <Text style={styles.sectionHeader}>Contact Information</Text>
          <TouchableOpacity
            onPress={onContactInfoEdit}
            testID="ConfirmInfoEditContactInfoButton"
          >
            <Text style={styles.sectionEdit}>Edit</Text>
          </TouchableOpacity>
        </View>
        <AddressInputs
          street1={contactInfo.street}
          street2={contactInfo.street2}
          city={contactInfo.city}
          state={contactInfo.state}
          postalCode={contactInfo.postalCode}
          ugamiCardApp
          verifyForm
          setState={() => {}}
          setCity={() => {}}
          setStreet1={() => {}}
          setStreet2={() => {}}
          setPostalCode={() => {}}
          setPostalCodeError={(_: string) => {}}
        />
        <LabeledInput label="Email" value={contactInfo.email} disabled />
        <LabeledInput
          label="Phone Number"
          value={formatNumber(contactInfo.phoneNumber)}
          accessoryLeft={renderCallingCode}
          disabled
        />
      </View>
      {isShippingChanged && contactInfo.shippingAddress && (
        <View style={styles.form}>
          <View style={styles.sectionHeaderRow}>
            <Text style={styles.sectionHeader}>Card Shipping Address</Text>
            <TouchableOpacity
              onPress={onContactInfoEdit}
              testID="ConfirmInfoEditCardShippingButton"
            >
              <Text style={styles.sectionEdit}>Edit</Text>
            </TouchableOpacity>
          </View>
          <AddressInputs
            street1={contactInfo.shippingAddress.street}
            street2={contactInfo.shippingAddress.street2}
            city={contactInfo.shippingAddress.city}
            state={contactInfo.shippingAddress.state}
            postalCode={contactInfo.shippingAddress.postalCode}
            ugamiCardApp
            verifyForm
            setState={() => {}}
            setCity={() => {}}
            setStreet1={() => {}}
            setStreet2={() => {}}
            setPostalCode={() => {}}
            setPostalCodeError={(_: string) => {}}
          />
        </View>
      )}
      <View style={styles.disclaimerContainer}>
        <CheckBox
          status="basic"
          checked={check}
          onChange={(nextChecked) => setCheck(nextChecked)}
        />
        <View style={styles.textWithLink}>
          <Text style={styles.disclaimer}>
            I have read and accepted the{' '}
            <TouchableOpacity
              style={[Platform.OS === 'android' ? { height: 15 } : {}]}
              onPress={onGoToEsignConsent}
            >
              <Text style={styles.disclaimerRed}>
                Electronic Disclosure and Consent
              </Text>
            </TouchableOpacity>
            ,{' '}
            <TouchableOpacity
              style={[Platform.OS === 'android' ? { height: 15 } : {}]}
              onPress={onGoToConsumerDeposit}
            >
              <Text style={styles.disclaimerRed}>
                Consumer Deposit Agreement
              </Text>
            </TouchableOpacity>
            {', '}
            <TouchableOpacity
              style={[Platform.OS === 'android' ? { height: 15 } : {}]}
              onPress={onGoToDebitCardholder}
            >
              <Text style={styles.disclaimerRed}>
                Debit Cardholder Agreement
              </Text>
            </TouchableOpacity>
            ,{' '}
            <TouchableOpacity
              style={[Platform.OS === 'android' ? { height: 15 } : {}]}
              onPress={onGoToRewards}
            >
              <Text style={styles.disclaimerRed}>Rewards Terms </Text>
            </TouchableOpacity>
            and{' '}
            <TouchableOpacity
              style={[Platform.OS === 'android' ? { height: 15 } : {}]}
              onPress={onGoToSweepstakes}
            >
              <Text style={styles.disclaimerRed}>Sweepstakes Rules</Text>
            </TouchableOpacity>{' '}
            and I consent to the use of electronic records in connection with
            this application.
          </Text>
        </View>
      </View>
      <Button
        size="large"
        style={styles.submitButton}
        onPress={onNext}
        disabled={loading || !check}
        accessoryLeft={loading ? LoadingIndicator : undefined}
        testID="ConfirmInfoSubmitButton"
      >
        SUBMIT APPLICATION
      </Button>
    </ContainerScrollView>
  );
};

const themedStyles = StyleService.create({
  inputAccessory: {
    marginRight: 8,
  },
  button: {
    alignSelf: 'stretch',
  },
  row: {
    flexDirection: 'row',
  },
  columnFirstName: {
    flex: 1,
    marginRight: 8,
  },
  columnLastName: {
    flex: 1,
    marginLeft: 8,
  },
  form: {
    marginBottom: 36,
  },
  submitButton: {
    flex: 1,
  },
  buttonContainer: {
    margin: 16,
  },
  container: {
    flex: 1,
    backgroundColor: 'app-background',
  },
  headerContainer: {
    marginHorizontal: 16,
    marginTop: 20,
    marginBottom: 48,
  },
  header: {
    fontSize: 23,
    lineHeight: 32,
    fontFamily: 'Jura_700Bold',
    color: 'text-primary',
    textAlign: 'center',
    marginBottom: 16,
  },
  headerSub: {
    fontSize: 16,
    lineHeight: 22.5,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'text-primary',
    textAlign: 'center',
  },
  sectionHeaderRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  sectionHeader: {
    fontSize: 23,
    lineHeight: 32,
    fontFamily: 'Jura_700Bold',
    color: 'text-primary',
    textAlign: 'left',
  },
  sectionEdit: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: 'SourceSansPro_600SemiBold',
    color: 'red-03',
    textAlign: 'center',
    marginLeft: 8,
  },
  imageBg: {
    flex: 1,
  },
  scrollContainer: {
    flex: 1,
  },
  spacer: {
    margin: 32,
  },
  addressForm: {
    marginTop: 0,
  },
  callingCodeContainer: {
    padding: 8,
    marginRight: 8,
    marginLeft: -8,
    backgroundColor: 'rgba(196, 196, 196, 0.15)',
    borderRadius: 4,
  },
  callingCode: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
  },
  disclaimerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 16,
    marginBottom: 24,
  },
  disclaimer: {
    fontSize: 14,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
  },
  disclaimerRed: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_700Bold',
    color: 'red-03',
  },
  textWithLink: {
    flex: 1,
    paddingLeft: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
  },
});

export default observer(ConfirmInfo);
