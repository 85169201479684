export default {
    meta: {},
    appearances: {
        default: {
            mapping: {
                borderRadius: 4,
                borderColor: 'blue-gray-01',
                backgroundColor: 'blue-gray-01',
                paddingHorizontal: 8,
                paddingVertical: 8,
                indicatorBackgroundColor: 'blue-gray-01',
                textMarginHorizontal: 4,
                textFontSize: 14,
                textFontFamily: 'SourceSansPro_400Regular',
                textColor: 'text-dark-primary',
            },
        },
    },
}