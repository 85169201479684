import Button from './Components/Button';
import Layout from './Components/Layout';
import Input from './Components/Input';
import CheckBox from './Components/CheckBox';
import Tooltip from './Components/Tooltip';
import Select from './Components/Select';
import Spinner from './Components/Spinner';
import Popover from './Components/Popover';
import SelectOption from './components/SelectOption';

export default {
    strict: {},
    components: {
        Layout,
        Button,
        Input,
        CheckBox,
        Tooltip,
        Select,
        Spinner,
        Popover,
        SelectOption
    },
} as any;
