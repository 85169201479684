import {
  useStyleSheet,
  StyleService,
  Text,
  Select,
  SelectItem,
  IndexPath,
  useTheme,
} from '@ui-kitten/components';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useState, useEffect, useCallback } from 'react';
import { View, TextStyle, StyleProp, Keyboard } from 'react-native';

import { getTestID } from '../../utils/testID';
import LabeledInput from '../Common/LabeledInput';

type Props = {
  label?: string;
  data: { [key: string]: string };
  onSelect: (value: string) => void;
  onSelectOther?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  size?: string;
  status?: string;
  placeholder?: string;
  error?: any;
  inputError?: any;
  value?: string;
  disabled?: boolean;
  labelStyle?: StyleProp<TextStyle>;
  labelAccesory?: () => JSX.Element;
  isMonth?: boolean;
};

const DropDownMenu: React.FC<Props> = ({
  label,
  data,
  onSelect,
  onSelectOther,
  size = 'large',
  status = 'basic',
  placeholder,
  error,
  inputError,
  value = '',
  disabled = false,
  onFocus,
  onBlur,
  labelStyle,
  labelAccesory,
  isMonth,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [selectedIndex, setSelectedIndex] = useState<IndexPath>();
  const theme = useTheme();
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    const index = Object.keys(data).indexOf(value);
    setSelectedIndex(index < 0 ? undefined : new IndexPath(index));
  }, [data, value]);

  const isOther = (index?: IndexPath) => {
    return index && Object.keys(data)[index.row].toLowerCase() === 'others';
  };

  const onPressIn = () => {
    Keyboard.dismiss();
    setIsFocus(true);
  };

  const onDropDownBlur = () => {
    setIsFocus(false);
    if (onBlur) {
      onBlur();
    }
  };

  const onSelectItem = (index: IndexPath) => {
    setSelectedIndex(index);
    onSelect(Object.keys(data)[index.row]);

    if (onSelectOther) {
      if (!isOther(index) && onSelectOther) {
        onSelectOther(Object.keys(data)[index.row]);
      } else {
        onSelectOther('');
      }
    }
  };

  const onChangeText = (value: string) => {
    if (isOther(selectedIndex) && onSelectOther) {
      onSelectOther(value);
    }
  };

  const renderSelectItem = useCallback((data, value, index, props) => {
    return (
      <>
        {isMonth ? (
          <Text {...props}>
            {data[value].split(' ')[0]}{' '}
            <Text style={[props?.style, { opacity: 0.5 }]}>
              {isMonth ? data[value].split(' ')[1] : ''}
            </Text>
          </Text>
        ) : (
          <Text {...props}>{Object.values(data)[index]}</Text>
        )}
      </>
    );
  }, []);

  return (
    <View style={styles.container}>
      {label ? (
        <View style={styles.label}>
          <View style={styles.row}>
            <Text style={[styles.textWithOpacity, labelStyle]}>{label}</Text>
            {!!labelAccesory && labelAccesory()}
          </View>
          {!!error && <Text style={styles.error}>{error.message}</Text>}
        </View>
      ) : (
        <View style={styles.emptyLabel} />
      )}
      <View style={styles.selectContainer}>
        <LinearGradient
          colors={[
            error
              ? theme['orange-03']
              : isFocus
              ? theme['red-03']
              : 'transparent',
            'transparent',
          ]}
          style={[
            isFocus || error
              ? styles.gradientInputContainer
              : styles.inputContainer,
            size === 'medium' && { minHeight: 40 },
          ]}
        >
          <View style={error || isFocus ? styles.focus : styles.selectBorder}>
            <Select
              disabled={disabled}
              selectedIndex={selectedIndex}
              status={status}
              size={size}
              placeholder={placeholder}
              style={styles.select}
              onPressIn={onPressIn}
              onBlur={onDropDownBlur}
              onFocus={onFocus}
              onSelect={(index) => onSelectItem(index as IndexPath)}
              value={
                isOther(selectedIndex)
                  ? () => (
                      <Text style={styles.other}>
                        Others
                        <Text style={[styles.other, styles.otherWithOpacity]}>
                          {' '}
                          (please specify)
                        </Text>
                      </Text>
                    )
                  : selectedIndex
                  ? (props) =>
                      renderSelectItem(data, value, selectedIndex.row, props)
                  : ''
              }
              testID={getTestID(`${label}Select`)}
            >
              {Object.keys(data).map((value, index) => (
                <SelectItem
                  title={(props) => renderSelectItem(data, value, index, props)}
                  key={value}
                  testID={getTestID(`SelectItem${value}`)}
                />
              ))}
            </Select>
          </View>
        </LinearGradient>

        {isOther(selectedIndex) && (
          <LabeledInput
            placeholder="Others"
            autoCapitalize="none"
            autoCorrect={false}
            onChangeText={onChangeText}
            error={inputError}
          />
        )}
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  inputContainer: {
    alignSelf: 'stretch',
    alignItems: 'center',
    minHeight: 48,
    padding: 1,
    marginTop: 8,
  },
  gradientInputContainer: {
    alignSelf: 'stretch',
    alignItems: 'center',
    minHeight: 48,
    borderRadius: 2,
    padding: 1,
    marginTop: 8,
  },
  textWithOpacity: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    alignSelf: 'flex-start',
    opacity: 0.5,
  },
  label: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 4,
    paddingTop: 24,
  },
  emptyLabel: {
    paddingTop: 16,
  },
  error: {
    fontSize: 12,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'orange-03',
    textTransform: 'lowercase',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  warning: {
    flex: 1,
    borderWidth: 1,
    borderColor: 'orange-03',
    borderBottomColor: 'transparent',
  },
  selectContainer: {
    alignSelf: 'stretch',
  },
  selectBorder: {
    alignSelf: 'stretch',
    borderColor: 'gray-02',
    borderWidth: 1,
    borderRadius: 2,
  },
  select: {
    alignSelf: 'stretch',
  },
  focus: {
    alignSelf: 'stretch',
    borderColor: 'transparent',
    backgroundColor: 'brand-field-focused-background',
    borderWidth: 1,
    borderRadius: 2,
  },
  other: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: 'SourceSansPro_400Regular',
  },
  otherWithOpacity: {
    opacity: 0.5,
  },
});

export default DropDownMenu;
