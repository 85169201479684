export default {
    meta: {},
    appearances: {
        default: {
            mapping: {},
            variantGroups: {
                level: {
                    '1': {
                        backgroundColor: 'focused-background',
                    },
                },
            },
        },
    }
}