import {
  useStyleSheet,
  StyleService,
  Text,
  Tooltip,
} from '@ui-kitten/components';
import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';

import Info from '../../assets/images/info-filled.svg';
import { PasswordStrength } from '../../constants/PasswordStrength';
import SvgIcon from '../Common/SvgIcon'

type Props = {
  password: string;
  passwordStrength: number;
  setPasswordStrength: (value: number) => void;
};

const PasswordStrengthChecker: React.FC<Props> = ({
  password,
  passwordStrength,
  setPasswordStrength,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [passwordMessageVisible, setPasswordMessageVisible] = useState(false);

  const renderPasswordInfoButton = () => (
    <TouchableOpacity onPress={() => setPasswordMessageVisible(true)}>
      <SvgIcon>
        <Info />
      </SvgIcon>
    </TouchableOpacity>
  );

  useEffect(() => {
    if (password.length < 8) {
      setPasswordStrength(PasswordStrength.NONE);
      return;
    }

    // at least 8 characters, one capital letter, one lower case letter, one number, and one symbol
    const strongPassword = new RegExp(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
    );

    // at least 8 characters, atleast two of the following: capital letter, lower case letter, number, and symbol
    const mediumPassword = new RegExp(
      '((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[^A-Za-z0-9]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[^A-Za-z0-9]))|((?=.*[0-9])(?=.*[^A-Za-z0-9]))(?=.{8,})',
    );

    if (strongPassword.test(password)) {
      setPasswordStrength(PasswordStrength.STRONG);
    } else if (mediumPassword.test(password)) {
      setPasswordStrength(PasswordStrength.MEDIUM);
    } else {
      setPasswordStrength(PasswordStrength.WEAK);
    }
  }, [password]);

  return (
    <>
      <View style={[styles.row, styles.passwordStrengthContainer]}>
        <Text style={styles.passwordStrength}>Password strength</Text>
        <Tooltip
          visible={passwordMessageVisible}
          anchor={renderPasswordInfoButton}
          onBackdropPress={() => setPasswordMessageVisible(false)}
          placement="top end"
        >
          {(evaProps) => (
            <Text {...evaProps} style={styles.tooltip}>
              Your password must be at least 8 characters long, have one capital
              letter, one lower case letter, one number, and one symbol.
            </Text>
          )}
        </Tooltip>
      </View>
      <View style={styles.row}>
        <View
          style={[
            styles.strengthIndicator,
            styles.strengthIndicatorLeft,
            passwordStrength === PasswordStrength.NONE && styles.empty,
            passwordStrength === PasswordStrength.WEAK && styles.weak,
            passwordStrength === PasswordStrength.MEDIUM && styles.medium,
            passwordStrength === PasswordStrength.STRONG && styles.strong,
          ]}
        />
        <View
          style={[
            styles.strengthIndicator,
            passwordStrength < PasswordStrength.MEDIUM && styles.empty,
            passwordStrength === PasswordStrength.MEDIUM && styles.medium,
            passwordStrength === PasswordStrength.STRONG && styles.strong,
          ]}
        />
        <View
          style={[
            styles.strengthIndicator,
            styles.strengthIndicatorRight,
            passwordStrength < PasswordStrength.STRONG && styles.empty,
            passwordStrength === PasswordStrength.STRONG && styles.strong,
          ]}
        />
      </View>
    </>
  );
};

const themedStyles = StyleService.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  passwordStrengthContainer: {
    alignSelf: 'flex-start',
    marginTop: 28,
  },
  passwordStrength: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    paddingRight: 8,
  },
  strengthIndicator: {
    flex: 1,
    marginHorizontal: 4,
    height: 8,
    marginTop: 16,
  },
  strengthIndicatorLeft: {
    borderTopLeftRadius: 60,
    borderBottomLeftRadius: 60,
  },
  strengthIndicatorRight: {
    borderTopRightRadius: 60,
    borderBottomRightRadius: 60,
  },
  empty: {
    backgroundColor: 'white',
    opacity: 0.4,
  },
  weak: {
    backgroundColor: 'orange-03',
  },
  medium: {
    backgroundColor: 'yellow-03',
  },
  strong: {
    backgroundColor: 'green-03',
  },
  tooltip: {
    maxWidth: 220,
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    lineHeight: 24,
    color: 'text-dark-primary',
  },
});

export default PasswordStrengthChecker;
