import * as Font from 'expo-font';
import { useState, useEffect, useRef } from 'react';
import * as Sentry from 'sentry-expo';

import config from '../config';
import { FontMapping } from '../constants/Fonts';
import { NativeStorageService } from '../services/storage/storage';
import { StorageService } from '../services/storage/storageService';
import { createStore, Store } from '../stores';

export default () => {
  const [storageService] = useState<StorageService>(new NativeStorageService());
  const [store, setStore] = useState<Store>();
  const [ready, setReady] = useState(false);

  const { appVersion } = config;
  const fontLoad = async () => {
    await Font.loadAsync(FontMapping);
  }

  useEffect(() => {
    Sentry.init({
      dsn: config.sentry.dsn,
      enableNative: false,
    });
    (async () => {
      const store = createStore(storageService);
      setStore(store);
      setReady(true);
      // Set user in Sentry.
      const user = store.authStore.user;
      if (user) {
        Sentry.Native.setUser({ email: user.email });
      }
    })();
    fontLoad()
  }, []);

  return {
    store,
    ready: !Object.values(ready).includes(false),
  };
};
