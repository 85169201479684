import {
  useStyleSheet,
  StyleService,
  Text,
  Icon,
  useTheme,
} from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  runOnJS,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  message: string;
  success?: boolean;
  error?: boolean;
  toastDuration?: number;
};

const Toast: React.FC<Props> = ({
  visible,
  setVisible,
  message,
  success = false,
  error = false,
  toastDuration = 300,
}) => {
  const styles = useStyleSheet(themedStyles);
  const insets = useSafeAreaInsets();
  const theme = useTheme();
  const screenWidth = useWindowDimensions().width;
  const width = screenWidth - 32;
  const [duration, setDuration] = useState(1);

  const translateX = useSharedValue(-screenWidth);
  const opacity = useSharedValue(0);

  useEffect(() => {
    if (visible) {
      opacity.value = withTiming(1, {
        duration: 1000,
      });
      translateX.value = withTiming(0, {
        duration: 1000,
      });
    }
  }, [visible]);

  useEffect(() => {
    let interval: any;
    if (visible) {
      interval = setInterval(() => {
        if (duration === 0) {
          opacity.value = withTiming(
            0,
            {
              duration: 400,
            },
            (finished?: boolean) => {
              if (finished) {
                runOnJS(closeToast)();
              }
            },
          );
        } else {
          setDuration(duration - 1);
        }
      }, toastDuration);
    }
    return () => {
      clearInterval(interval);
    };
  }, [duration, visible]);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: translateX.value }],
    opacity: opacity.value,
  }));

  const closeToast = () => {
    setVisible(false);
    translateX.value = -screenWidth;
  };

  if (!visible) {
    return null;
  }

  return (
    <Animated.View
      style={[styles.container, { width, top: insets.top + 20 }, animatedStyle]}
    >
      {success && (
        <Icon
          name="checkmark-circle-2"
          width={28}
          height={28}
          fill={theme['green-02']}
        />
      )}
      {error && (
        <Icon
          name="close-circle"
          width={28}
          height={28}
          fill={theme['red-03']}
        />
      )}
      <Text style={styles.message}>{message}</Text>
    </Animated.View>
  );
};

const themedStyles = StyleService.create({
  container: {
    position: 'absolute',
    left: 16,
    backgroundColor: 'blue-gray-10',
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderRadius: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  message: {
    fontFamily: 'SourceSansPro_400Regular',
    fontSize: 14,
    marginLeft: 16,
  },
});

export default Toast;
