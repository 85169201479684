export default {
    meta: {},
    appearances: {
        default: {
            mapping: {
                width: 28,
                height: 28,
                borderRadius: 2,
                borderWidth: 'border-width',
                textFontSize: 16,
                textFontFamily: 'SourceSansPro_400Regular',
                iconWidth: 16,
                textMarginHorizontal: 16,
                iconHeight: 16,
            },
            variantGroups: {
                status: {
                    basic: {
                        borderColor: 'white',
                        backgroundColor: '#1B1B1B',
                        state: {
                            checked: {
                                borderColor: 'blue-gray-02',
                                backgroundColor: 'blue-gray-02',
                                iconTintColor: 'app-background',
                            },
                            active: {
                                borderColor: 'white',
                                backgroundColor: 'brand-field-background',
                                outlineBackgroundColor: 'transparent',
                            },
                            hover: {
                                borderColor: 'white',
                                backgroundColor: 'brand-field-background',
                            },
                            'checked.hover': {
                                borderColor: 'blue-gray-05',
                                backgroundColor: 'blue-gray-05',
                            },
                            'checked.active': {
                                borderColor: 'blue-gray-02',
                                backgroundColor: 'blue-gray-02',
                                outlineBackgroundColor: 'transparent',
                            },
                        },
                    },
                },
            },
        },
    },
}