import { addSeconds, subHours, getUnixTime } from 'date-fns';
import { isUndefined, compact, orderBy } from 'lodash';
import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  getRoot,
  ModelCreationData,
  modelAction,
  prop,
  objectMap,
} from 'mobx-keystone';

import { DocumentStatus } from '../constants/DocumentStatus';
import { PinStatus } from '../constants/PinStatus';
import { UgamiCardApplicationStatus } from '../constants/UgamiCardApplicationStatus';
import {
  ApplicationFormData,
  DepositAccountFormData,
  DebitCardFormData,
  CustomerVerificationTokenFormData,
  CustomerTokenFormData,
  VirtualDebitCardFormData,
  ReplacementDebitCardFormData,
  FeeFormData,
} from '../constants/UgamiCardFormData';
import { UgamiCardStatus } from '../constants/UgamiCardStatus';
import { UgamiCardType } from '../constants/UgamiCardType';
import Address from '../models/Address';
import CustomerToken from '../models/CustomerToken';
import DepositAccount from '../models/DepositAccount';
import Phone from '../models/Phone';
import UgamiCard from '../models/UgamiCard';
import UgamiCardApplication from '../models/UgamiCardApplication';
import UgamiCardApplicationDocument from '../models/UgamiCardApplicationDocument';
import UgamiCardPinStatus from '../models/UgamiCardPinStatus';
import * as api from '../services/api';
import { track, logEventAF } from '../utils/analytics';
import { getError, getSuccess } from '../utils/models';
import Store from './Store';

@model('ugami-app/UgamiCardStore')
export default class UgamiCardStore extends Model({
  cards: prop(() => objectMap<UgamiCard>()),
  depositAccounts: prop(() => objectMap<DepositAccount>()),
  customerToken: prop<CustomerToken | null>(null),
  cardApplication: prop<UgamiCardApplication | null>(null),
  cardShippingAddress: prop<Address | null>(null),
  requiredApplicationDocuments: prop(() =>
    objectMap<UgamiCardApplicationDocument>(),
  ),
  cardsPinStatus: prop(() => objectMap<UgamiCardPinStatus>()),
  signedNonce: prop<string | null>(null),
  provisioningState: prop<string | null>(null),
}) {
  @observable
  loading = false;

  getCard = (id?: string): UgamiCard | undefined => {
    return Array.from(this.cards.values()).find((c) => c.id === id);
  };

  getActiveVirtualCard = (): UgamiCard | undefined => {
    return Array.from(this.cards.values()).find(
      (c) =>
        c.type === UgamiCardType.INDIVIDUAL_VIRTUAL_DEBIT_CARD &&
        (c.attributes.status === UgamiCardStatus.ACTIVE ||
          c.attributes.status === UgamiCardStatus.FROZEN),
    );
  };

  isVirtualCardExist = (): boolean => {
    return !isUndefined(
      Array.from(this.cards.values()).find(
        (c) => c.type === UgamiCardType.INDIVIDUAL_VIRTUAL_DEBIT_CARD,
      ),
    );
  };

  isPhysicalCardExist = (): boolean => {
    return !isUndefined(
      Array.from(this.cards.values()).find(
        (c) => c.type === UgamiCardType.INDIVIDUAL_DEBIT_CARD,
      ),
    );
  };

  getDepositAccount = (id?: string): DepositAccount | undefined => {
    return Array.from(this.depositAccounts.values()).find((c) => c.id === id);
  };

  getCards = (): UgamiCard[] => {
    const virtualCards = Array.from(this.cards.values()).filter(
      (c) => c.type === UgamiCardType.INDIVIDUAL_VIRTUAL_DEBIT_CARD,
    );

    const activeVirtualCard = virtualCards.find(
      (c) =>
        c.attributes.status === UgamiCardStatus.ACTIVE ||
        c.attributes.status === UgamiCardStatus.FROZEN,
    );

    let closeVirtualCard;
    if (!activeVirtualCard) {
      closeVirtualCard = virtualCards.find(
        (c) => c.attributes.status === UgamiCardStatus.CLOSED_BY_CUSTOMER,
      );
    }

    const physicalCards = Array.from(this.cards.values()).filter(
      (c) => c.type === UgamiCardType.INDIVIDUAL_DEBIT_CARD,
    );

    const suspectedFraudPhysicalCard = physicalCards.find(
      (c) => c.attributes.status === UgamiCardStatus.SUSPECTED_FRAUD,
    );

    const activePhysicalCard = physicalCards.find(
      (c) =>
        c.attributes.status === UgamiCardStatus.ACTIVE ||
        c.attributes.status === UgamiCardStatus.FROZEN,
    );

    const inActivePhysicalCard = physicalCards.find(
      (c) => c.attributes.status === UgamiCardStatus.INACTIVE,
    );

    let closedPhysicalCard;
    if (
      !activePhysicalCard &&
      !inActivePhysicalCard &&
      !suspectedFraudPhysicalCard
    ) {
      closedPhysicalCard = physicalCards.find(
        (c) =>
          c.attributes.status === UgamiCardStatus.STOLEN ||
          c.attributes.status === UgamiCardStatus.LOST ||
          c.attributes.status === UgamiCardStatus.CLOSED_BY_CUSTOMER,
      );
    }

    const filteredPhysicalCards = orderBy(
      compact([
        suspectedFraudPhysicalCard,
        activePhysicalCard,
        closedPhysicalCard,
        inActivePhysicalCard,
      ]),
      (c) => c.attributes.createdAt,
      ['desc'],
    );

    return compact([
      activeVirtualCard,
      closeVirtualCard,
      filteredPhysicalCards.length > 0 ? filteredPhysicalCards[0] : undefined,
    ]);
  };

  getCardPinStatus = (id?: string): PinStatus => {
    return (
      Array.from(this.cardsPinStatus.values()).find((c) => c.id === id)
        ?.pinStatus || PinStatus.NOT_SET
    );
  };

  getRequiredDocuments = (): UgamiCardApplicationDocument[] | undefined => {
    return Array.from(this.requiredApplicationDocuments.values()).filter(
      (d) =>
        d.attributes.status === DocumentStatus.REQUIRED ||
        d.attributes.status === DocumentStatus.INVALID,
    );
  };

  getInvalidDocuments = (): UgamiCardApplicationDocument[] | undefined => {
    return Array.from(this.requiredApplicationDocuments.values()).filter(
      (d) => d.attributes.status === DocumentStatus.INVALID,
    );
  };

  getDocument = (id?: string): UgamiCardApplicationDocument | undefined => {
    return Array.from(this.requiredApplicationDocuments.values()).find(
      (d) => d.id === id,
    );
  };

  @modelAction
  awaitingReviewApplication(data: { status: UgamiCardApplicationStatus }) {
    if (this.cardApplication) {
      this.cardApplication.setApplicationStatus(data.status);
    }
  }
  @modelAction
  approveApplication() {
    if (this.cardApplication) {
      this.cardApplication.approveApplication();
    }
  }

  @modelAction
  updateCardAttributes(data: { id: string; status: UgamiCardStatus }) {
    if (data && this.cards.has(data.id)) {
      const card = this.cards.get(data.id)!;
      card.updateAttributes(data.status);
    }
  }

  @modelAction
  createOrUpdateUgamiCard(data: ModelCreationData<UgamiCard>) {
    const id = `${data.id}`;

    let card: UgamiCard;
    if (this.cards.has(id)) {
      card = this.cards.get(id)!;
    } else {
      card = new UgamiCard(data);
      this.cards.set(id, card);
    }
    card.update(data);
  }

  @modelAction
  createOrUpdateUgamiCardsPinStatus(
    data: ModelCreationData<UgamiCardPinStatus>,
  ) {
    const id = `${data.id}`;

    let cardPinStatus: UgamiCardPinStatus;
    if (this.cardsPinStatus.has(id)) {
      cardPinStatus = this.cardsPinStatus.get(id)!;
    } else {
      cardPinStatus = new UgamiCardPinStatus(data);
      this.cardsPinStatus.set(id, cardPinStatus);
    }
    cardPinStatus.update(data);
  }

  @modelAction
  createOrUpdateUgamiCardPinStatus(id: string, pinStatus: PinStatus) {
    let cardPinStatus: UgamiCardPinStatus;
    if (this.cardsPinStatus.has(id)) {
      cardPinStatus = this.cardsPinStatus.get(id)!;
      cardPinStatus.updatePinStatus(pinStatus);
    } else {
      cardPinStatus = new UgamiCardPinStatus({ id, pinStatus });
      this.cardsPinStatus.set(id, cardPinStatus);
    }
  }

  @modelAction
  createOrUpdateUgamiDepositAccount(data: ModelCreationData<DepositAccount>) {
    const id = `${data.id}`;

    let depositAccount: DepositAccount;
    if (this.depositAccounts.has(id)) {
      depositAccount = this.depositAccounts.get(id)!;
    } else {
      depositAccount = new DepositAccount(data);
      this.depositAccounts.set(id, depositAccount);
    }
    depositAccount.update(data);
  }

  @modelAction
  updateDepositAccountBalance = (data: {
    accountId: string;
    balance: number;
    amount: number;
  }) => {
    const accountId = data.accountId;
    const balance = data.balance;
    const amount = data.amount;
    if (
      !isUndefined(accountId) &&
      !isUndefined(balance) &&
      !isUndefined(amount) &&
      this.depositAccounts.has(accountId)
    ) {
      const depositAccount = this.depositAccounts.get(accountId);
      if (depositAccount) {
        depositAccount.setBalance(balance, amount);
        const { attributes, id } = depositAccount;
        depositAccount.update({ attributes, id });
      }
    }
  };

  @modelAction
  setSignedNonce = (nonce: string) => {
    this.signedNonce = nonce;
  };

  @modelAction
  setProvisioningState = (state: string) => {
    this.provisioningState = state;
  };

  @modelAction
  updateHoldingBalanceAfterSuccessfulTransaction = (data: {
    accountId: string;
    balance: number;
    amount: number;
  }) => {
    const accountId = data.accountId;
    const balance = data.balance;
    const amount = data.amount;
    if (
      !isUndefined(accountId) &&
      !isUndefined(balance) &&
      !isUndefined(amount) &&
      this.depositAccounts.has(accountId)
    ) {
      const depositAccount = this.depositAccounts.get(accountId);
      if (depositAccount) {
        depositAccount.setHoldingBalanceAfterSuccessfulTransaction(
          balance,
          amount,
        );
        const { attributes, id } = depositAccount;
        depositAccount.update({ attributes, id });
      }
    }
  };

  @modelAction
  updateHoldingBalance = (data: { accountId: string; amount: number }) => {
    const accountId = data.accountId;
    const amount = data.amount;
    if (
      !isUndefined(accountId) &&
      !isUndefined(amount) &&
      this.depositAccounts.has(accountId)
    ) {
      const depositAccount = this.depositAccounts.get(accountId);
      if (depositAccount) {
        depositAccount.setHoldingBalance(amount);
        const { attributes, id } = depositAccount;
        depositAccount.update({ attributes, id });
      }
    }
  };

  @modelAction
  setUgamiCardApplication(data: ModelCreationData<UgamiCardApplication>) {
    this.cardApplication = new UgamiCardApplication(data);
  }

  @modelAction
  createOrUpdateUgamiCardApplicationDocument(
    data: ModelCreationData<UgamiCardApplicationDocument>,
  ) {
    const id = `${data.id}`;

    let applicationDocument: UgamiCardApplicationDocument;
    if (this.requiredApplicationDocuments.has(id)) {
      applicationDocument = this.requiredApplicationDocuments.get(id)!;
    } else {
      applicationDocument = new UgamiCardApplicationDocument(data);
      this.requiredApplicationDocuments.set(id, applicationDocument);
    }
    const rootStore = getRoot<Store>(this);
    applicationDocument.update(
      data,
      rootStore.authStore.user?.unitApplicationId || '',
    );
  }

  @modelAction
  updateCardData = (id: string, data: object) => {
    if (this.cards.has(id)) {
      const card = this.cards.get(id);
      if (card) {
        const {
          attributes,
          id,
          encPayload,
          relationships,
          type,
          addToWallet,
          walletCode,
          isEncPayloadLoading,
          provisioningStatus,
        } = card;
        card.update({
          attributes,
          id,
          encPayload,
          relationships,
          type,
          isEncPayloadLoading,
          addToWallet,
          walletCode,
          provisioningStatus,
          ...data,
        });
      }
    }
  };

  @modelFlow
  createUgamiCardApplication = _async(function* (
    this: UgamiCardStore,
    data: ApplicationFormData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities = {
      data: { id: '', attributes: {} },
      included: [],
    };

    try {
      const { response } = yield* _await(
        api.createUgamiCardApplication(rootStore.authStore.token, data),
      );
      entities = response.entities;
      rootStore.authStore.updateUserUnitApplicationAccountId(entities.data.id);
      // TODO: check for correct type.
      // @ts-ignore
      this.setUgamiCardApplication(entities.data);
      entities.included.forEach((document) =>
        this.createOrUpdateUgamiCardApplicationDocument(document),
      );
    } catch (error) {
      console.warn('[DEBUG] error creating ugami card application', error);
      return getError(error);
    }

    if (entities.included.length > 0) {
      this.loading = false;
      return getSuccess({ needAdditionalVerification: true });
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  createDepositAccount = _async(function* (
    this: UgamiCardStore,
    depositAccountData: DepositAccountFormData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<DepositAccount>;
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(
        api.createDepositAccount(rootStore.authStore.token, depositAccountData),
      ));
    } catch (error) {
      console.warn('[DEBUG] error creating deposit account', error);
      return getError(error);
    }

    if (data) {
      rootStore.authStore.updateUserUnitAccountId(data?.id || '');
      track('Created Deposit Account');
      logEventAF('Created Deposit Account');
      this.createOrUpdateUgamiDepositAccount(data);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  createDebitCard = _async(function* (
    this: UgamiCardStore,
    debitCardData: DebitCardFormData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<UgamiCard>;

    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(
        api.createDebitCard(rootStore.authStore.token, debitCardData),
      ));
    } catch (error) {
      console.warn('[DEBUG] error creating debit card', error);
      return getError(error);
    }

    if (data) {
      this.createOrUpdateUgamiCard(data);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  createVirtualDebitCard = _async(function* (
    this: UgamiCardStore,
    virtualDebitCardData: VirtualDebitCardFormData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<UgamiCard>;

    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(
        api.createVirtualDebitCard(
          rootStore.authStore.token,
          virtualDebitCardData,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error creating virtual debit card', error);
      return getError(error);
    }

    if (data) {
      this.createOrUpdateUgamiCard(data);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  createCustomerVerificationToken = _async(function* (
    this: UgamiCardStore,
    data: CustomerVerificationTokenFormData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities: any;

    try {
      ({
        response: { entities },
      } = yield* _await(
        api.createCustomerVerificationToken(rootStore.authStore.token, data),
      ));
    } catch (error) {
      console.warn('[DEBUG] error creating customer verification token', error);
      return getError(error);
    }

    let verificationToken: string | undefined;
    if (entities) {
      verificationToken = entities.data.attributes.verificationToken;
    }
    this.loading = false;
    return getSuccess({ verificationToken });
  });

  @modelFlow
  createCustomerToken = _async(function* (
    this: UgamiCardStore,
    data: CustomerTokenFormData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let entities: any;

    try {
      ({
        response: { entities },
      } = yield* _await(
        api.createCustomerToken(rootStore.authStore.token, data),
      ));
    } catch (error) {
      console.warn('[DEBUG] error creating customer token', error);
      return getError(error);
    }

    let token: string | undefined;
    if (entities) {
      token = entities.data.attributes.token;
      const expiresIn = entities.data.attributes.expiresIn;
      const newDate = getUnixTime(
        addSeconds(subHours(new Date(), 1), expiresIn ? expiresIn : 0),
      );
      const customerToken = new CustomerToken({
        token: token || '',
        expirationDate: newDate,
      });
      this.customerToken = customerToken;
    }

    this.loading = false;
    return getSuccess({ token });
  });

  @modelFlow
  replaceCard = _async(function* (
    this: UgamiCardStore,
    id: string,
    debitCardData: ReplacementDebitCardFormData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<UgamiCard>;

    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(
        api.replaceCard(rootStore.authStore.token, id, debitCardData),
      ));
    } catch (error) {
      console.warn('[DEBUG] error replacing debit card', error);
      return getError(error);
    }

    if (data) {
      this.createOrUpdateUgamiCard(data);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  createFee = _async(function* (this: UgamiCardStore, data: FeeFormData) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    try {
      yield* _await(api.createFee(rootStore.authStore.token, data));
    } catch (error) {
      console.warn('[DEBUG] error creating a fee', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchUgamiCards = _async(function* (this: UgamiCardStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let data: ModelCreationData<UgamiCard>[];
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(api.fetchUgamiCards(rootStore.authStore.token)));
    } catch (error) {
      console.warn('[DEBUG] error fetching ugami cards', error);
      return getError(error);
    }

    if (data && data.length > 0) {
      data.forEach((card) => this.createOrUpdateUgamiCard(card));
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchUgamiDepositAccounts = _async(function* (this: UgamiCardStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<DepositAccount>[];
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(
        api.fetchUgamiDepositAccounts(rootStore.authStore.token),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching deposit accounts', error);
      return getError(error);
    }

    if (data && data.length > 0) {
      data.forEach((depositAccount) =>
        this.createOrUpdateUgamiDepositAccount(depositAccount),
      );
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchUgamiCardApplication = _async(function* (this: UgamiCardStore) {
    const rootStore = getRoot<Store>(this);

    if (
      !rootStore.authStore ||
      !rootStore.authStore.token ||
      !rootStore.authStore.user?.unitApplicationId
    ) {
      return getSuccess();
    }

    this.loading = true;

    let data: ModelCreationData<UgamiCardApplication>;
    let phone: ModelCreationData<Phone>;
    let shippingAddress: ModelCreationData<Address>;
    try {
      ({
        response: {
          entities: { data, phone, shippingAddress },
        },
      } = yield* _await(
        api.fetchUgamiCardApplication(
          rootStore.authStore.token,
          rootStore.authStore.user.unitApplicationId,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching ugami card application', error);
      return getError(error);
    }

    if (data) {
      this.setUgamiCardApplication(data);
    }

    if (phone) {
      rootStore.userStore.createOrUpdatePhoneNumbers(phone);
    }

    if (shippingAddress) {
      this.cardShippingAddress = new Address(shippingAddress);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchUgamiCardApplicationDocuments = _async(function* (this: UgamiCardStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let data: ModelCreationData<UgamiCardApplicationDocument>[];
    try {
      if (this.cardApplication && this.cardApplication.id) {
        ({
          response: {
            entities: { data },
          },
        } = yield* _await(
          api.fetchUgamiCardApplicationDocuments(
            rootStore.authStore.token,
            this.cardApplication.id,
          ),
        ));
        data.forEach((cardDocument) =>
          this.createOrUpdateUgamiCardApplicationDocument(cardDocument),
        );
      }
    } catch (error) {
      console.warn(
        '[DEBUG] error fetching ugami card application documents',
        error,
      );
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchPinStatus = _async(function* (this: UgamiCardStore, id: string) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let data;
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(api.fetchPinStatus(rootStore.authStore.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error fetching pin status', error);
      return getError(error);
    }

    if (data) {
      const newData = { id, pinStatus: data.attributes.status };
      this.createOrUpdateUgamiCardsPinStatus(newData as any);
    }

    this.loading = false;
    return getSuccess({ status: data.attributes.status });
  });

  @modelFlow
  uploadCardApplicationDocument = _async(function* (
    this: UgamiCardStore,
    photo: any,
    documentId: string,
    isBackSide: string,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let entities;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.uploadCardApplicationDocument(
          rootStore.authStore.token,
          photo,
          rootStore.authStore.user?.unitApplicationId || '',
          documentId,
          isBackSide,
        ),
      ));
      if (entities) {
        this.createOrUpdateUgamiCardApplicationDocument(entities.data);
        rootStore.authStore.updateUserUnitDocumentUploadTime(
          entities.unitDocumentUploadTime,
        );
      }
    } catch (error) {
      console.warn('[DEBUG] uploading photo', error);
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  lockUgamiCard = _async(function* (this: UgamiCardStore, id: string) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let data: ModelCreationData<UgamiCard>;
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(api.lockUgamiCard(rootStore.authStore.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error locking ugami card', error);
      return getError(error);
    }

    if (data) {
      this.createOrUpdateUgamiCard(data);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  unlockUgamiCard = _async(function* (this: UgamiCardStore, id: string) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;

    let data: ModelCreationData<UgamiCard>;
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(api.unlockUgamiCard(rootStore.authStore.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error unlocking ugami card', error);
      return getError(error);
    }

    if (data) {
      this.createOrUpdateUgamiCard(data);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  reportLost = _async(function* (this: UgamiCardStore, id: string) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<UgamiCard>;
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(api.reportLost(rootStore.authStore.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error reporting lost ugami card', error);
      return getError(error);
    }

    if (data) {
      this.createOrUpdateUgamiCard(data);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  reportStolen = _async(function* (this: UgamiCardStore, id: string) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<UgamiCard>;
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(api.reportStolen(rootStore.authStore.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error reporting stolen ugami card', error);
      return getError(error);
    }

    if (data) {
      this.createOrUpdateUgamiCard(data);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  close = _async(function* (this: UgamiCardStore, id: string) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let data: ModelCreationData<UgamiCard>;
    try {
      ({
        response: {
          entities: { data },
        },
      } = yield* _await(api.close(rootStore.authStore.token, id)));
    } catch (error) {
      console.warn('[DEBUG] error closing ugami card', error);
      return getError(error);
    }

    if (data) {
      this.createOrUpdateUgamiCard(data);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  getMobileWalletPayload = _async(function* (this: UgamiCardStore, id: string) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.token) {
      return getSuccess();
    }

    this.loading = true;
    let result: { data: { attributes: { payload: string } } };
    this.updateCardData(id, { isEncPayloadLoading: true });
    try {
      ({
        response: { entities: result },
      } = yield* _await(
        api.getMobileWalletPayload(rootStore.authStore.token, id, {
          signedNonce: this.signedNonce || '',
        }),
      ));
    } catch (error) {
      this.updateCardData(id, { isEncPayloadLoading: false });
      console.warn('[DEBUG] error getting ugami card encrypted payload', error);
      return getError(error);
    }
    if (!isUndefined(result) && !isUndefined(result.data.attributes.payload)) {
      this.updateCardData(id, {
        encPayload: result.data.attributes.payload,
      });
    }
    this.loading = false;
    return getSuccess({ payload: result.data.attributes.payload });
  });
}
