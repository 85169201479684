import { default as snakeCaseKeys } from 'snakecase-keys';
import config from '../../config';

import {
  CardCreationData,
  PaymentData,
  CardUpdateData,
  BookPaymentData,
} from '../../constants/PaymentForm';
import BankAccount from '../../models/BankAccount';
import { callApiWithToken } from './base';

export const addCard = async (token: string, data: Partial<CardCreationData>) =>
  callApiWithToken(
    config.urls.cards,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const removeBankAccount = async (token: string, id: string) =>
  callApiWithToken(`${config.urls.banks}${id}/`, token, 'DELETE', {
    'Content-Type': 'application/json',
  });

export const updateBankAccount = async (
  token: string,
  id: string,
  data: Partial<BankAccount>,
) =>
  callApiWithToken(
    `${config.urls.banks}${id}/`,
    token,
    'PATCH',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const payment = async (token: string, data: PaymentData) =>
  callApiWithToken(
    config.urls.payments,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const bookPayment = async (token: string, data: BookPaymentData) =>
  callApiWithToken(
    config.urls.bookPayment,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchLinkToken = async (token: string) =>
  callApiWithToken(config.urls.linkToken, token, 'GET');

export const fetchBankAccounts = async (token: string) =>
  callApiWithToken(config.urls.banks, token, 'GET');

export const fetchRecurringDeposits = async (token: string) =>
  callApiWithToken(config.urls.recurringDeposits, token, 'GET');

export const fetchPublicKey = async (token: string) =>
  callApiWithToken(config.urls.publicKey, token, 'GET');

export const fetchCards = async (token: string) =>
  callApiWithToken(config.urls.cards, token, 'GET');

export const updateCard = async (
  token: string,
  id: string,
  data: Partial<CardUpdateData>,
) =>
  callApiWithToken(
    `${config.urls.cards}${id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const deleteCard = async (token: string, id: string) =>
  callApiWithToken(`${config.urls.cards}${id}/`, token, 'DELETE', {
    'Content-Type': 'application/json',
  });

export const ugamiTransferToBank = async (token: string, data: object) => {
  return callApiWithToken(
    config.urls.ugamiTransferToBank,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );
};
