import { useNavigation } from '@react-navigation/native';
import {
  useStyleSheet,
  StyleService,
  Text,
  Button,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Image, View, ImageStyle, useWindowDimensions } from 'react-native';
import Svg, { G, Path, Defs } from 'react-native-svg';

import BulletEarn from '../../assets/images/bullet-earn.svg';
import BulletExclusive from '../../assets/images/bullet-exclusive.svg';
import BulletMore from '../../assets/images/bullet-more.svg';
import BulletUgiPoints from '../../assets/images/bullet-ugipoints.svg';
import SvgIcon from '../Common/SvgIcon';
import { AppStackNavigation, AuthStackNavigation } from '../../navigation';
import { useStore } from '../../stores';

type Props = { isAccountCreated?: boolean; isAuthStack?: boolean };

const SVG_WIDTH = 375;
const SVG_HEIGHT = 60;
const MoreInfo: React.FC<Props> = ({
  isAccountCreated = false,
  isAuthStack = false,
}) => {
  const styles = useStyleSheet(themedStyles);
  const navigation = useNavigation<AppStackNavigation & AuthStackNavigation>();

  const width = useWindowDimensions().width;
  const svgHeight = (width / SVG_WIDTH) * SVG_HEIGHT;
  const store = useStore();

  const onApplyPressed = () => {
    if (isAccountCreated) {
      store.setFirstTimeNavigation(true);
      store.setIsSignInComplete(true);
      store.setIsSignUpComplete(false);
    } else if (!isAuthStack) {
      navigation.navigate('Apply for Ugami Card');
    } else {
      navigation.navigate('Create Your Account');
    }
  };

  return (
    <View
      style={styles.bulletBackgroundContainer}
      testID="UgamiCardApplicationMoreInfo"
    >
      <View style={styles.pointBadgeContainer}>
        <Image
          style={styles.pointBadgeImage as ImageStyle}
          source={require('../../assets/images/point-badges.png')}
        />
      </View>
      <View style={styles.svg}>
        <Svg width={width} height={svgHeight} viewBox="0 0 375 60" fill="none">
          <G>
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M128.219 10.9359C126.354 5.34076 121.449 1 115.551 1H24C10.7452 1 0 11.7452 0 25.0001L0 1240H375V25C375 11.7452 364.255 1 351 1H260.449C254.551 1 249.646 5.34077 247.781 10.9359C239.44 35.9592 215.827 54 188 54C160.173 54 136.56 35.9592 128.219 10.9359Z"
              fill="#1C1E21"
              fillOpacity={0.5}
            />
            <Path
              d="M-0.5 1240V1240.5H0H375H375.5V1240V25C375.5 11.469 364.531 0.5 351 0.5H260.449C254.29 0.5 249.224 5.02528 247.306 10.7778C239.031 35.6031 215.605 53.5 188 53.5C160.395 53.5 136.969 35.6031 128.694 10.7778C126.776 5.02527 121.71 0.5 115.551 0.5H24C10.469 0.5 -0.5 11.4691 -0.5 25.0001V1240Z"
              stroke="#252525"
            />
          </G>
          <Defs />
        </Svg>
      </View>
      <View style={styles.bulletContentContainer}>
        <View style={styles.headerContainer}>
          <Text style={styles.header}>Join Ugami Today</Text>
        </View>
        <View style={styles.bulletContainer}>
          <SvgIcon>
            <BulletUgiPoints />
          </SvgIcon>
          <View style={styles.bulletContentColumn}>
            <Text style={styles.bulletContentText}>
              Earn{' '}
              <Text style={styles.ugiPoints}>
                ugipoints<Text style={styles.up}>(UP)</Text>
              </Text>{' '}
              For Every Dollar You Spend And Achievement You Unlock
            </Text>
          </View>
        </View>
        <View style={styles.bulletContainer}>
          <SvgIcon>
            <BulletEarn />
          </SvgIcon>

          <View style={styles.bulletContentColumn}>
            <Text style={styles.bulletContentText}>
              Redeem{' '}
              <Text style={styles.ugiPoints}>
                ugipoints<Text style={styles.up}>(UP)</Text>
              </Text>{' '}
              In The Rewards Store For Games You Know And Love
            </Text>
          </View>
        </View>
        <View style={styles.bulletContainer}>
          <SvgIcon>
            <BulletExclusive />
          </SvgIcon>
          <View style={styles.bulletContentColumn}>
            <Text style={styles.bulletContentText}>
              Access Exclusive Live Events And Gaming Sessions With MLG Teams
              And VIP’s
            </Text>
          </View>
        </View>
        <View style={styles.bulletContainer}>
          <SvgIcon>
            <BulletMore />
          </SvgIcon>
          <View style={styles.bulletContentColumn}>
            <Text style={styles.bulletContentText}>
              Score New Gaming Hardware, Software And More With Your{' '}
              <Text style={styles.ugiPoints}>
                ugipoints<Text style={styles.up}>(UP)</Text>
              </Text>
            </Text>
          </View>
        </View>
        <View style={styles.separator} />
        <Text style={styles.header}>What are you waiting for?</Text>
        <View style={styles.spacer} />
        <Text style={[styles.textBig, styles.messageText]}>
          Apply today if you’re ready to join Ugami’s elite group of gamers
          looking to elevate their financial lifestyle.
        </Text>
        <View style={styles.spacer} />
        <View style={styles.spacer} />
        <Button
          size="large"
          onPress={onApplyPressed}
          testID="UgamiCardApplyButton2"
        >
          UNLOCK YOUR UGAMI DEBIT CARD
        </Button>
        <Text style={styles.disclaimer}>
          Ugami is a financial technology company and is not a bank. Banking
          services provided by Blue Ridge Bank, N.A.; Member FDIC. The Ugami
          Visa Debit Card is issued by Blue Ridge Bank, N.A. pursuant to a
          license from Visa U.S.A. Inc. and may be used anywhere Visa cards are
          accepted.
        </Text>
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  svg: {
    marginTop: 80,
    alignItems: 'center',
  },
  bulletBackgroundContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  bulletContainer: {
    marginVertical: 20,
    flexDirection: 'row',
  },
  bulletContentColumn: {
    marginLeft: 16,
    flex: 1,
  },
  bulletContentText: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: 'SourceSansPro_400Regular',
  },
  messageText: {
    color: 'text-primary',
    textAlign: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: 'app-background',
  },
  contentContainer: {
    paddingHorizontal: 16,
  },
  bulletContentContainer: {
    paddingTop: 20,
    paddingBottom: 40,
    paddingHorizontal: 16,
    backgroundColor: 'brand-field-background',
  },
  headerContainer: {
    marginBottom: 20,
    alignItems: 'center',
  },
  header: {
    fontSize: 24,
    lineHeight: 32,
    fontFamily: 'Jura_700Bold',
    color: 'text-primary',
    textAlign: 'center',
  },
  textBig: {
    fontSize: 16,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'text-primary',
  },
  separator: {
    borderWidth: 0.5,
    borderColor: 'gray-02',
    margin: 24,
  },
  pointBadgeContainer: {
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  pointBadgeImage: {
    width: 135,
    height: 135,
  },
  spacer: {
    padding: 4,
    paddingBottom: 8,
  },
  disclaimer: {
    fontSize: 14,
    lineHeight: 24,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'text-light-secondary',
    marginTop: 24,
  },
  ugiPoints: {
    fontFamily: 'Neuropolitical_Ugami',
    fontSize: 14,
  },
  up: {
    fontFamily: 'Neuropolitical_Ugami',
    fontSize: 12,
  },
});

export default observer(MoreInfo);
