import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import { PinStatus } from '../constants/PinStatus';

@model('ugami-app/UgamiCardPinStatus')
export default class UgamiCardPinStatus extends Model({
  id: prop<string>(''),
  pinStatus: prop<PinStatus>(),
}) {
  @modelAction
  update(data: ModelCreationData<UgamiCardPinStatus>) {
    Object.assign(this, data);
  }

  @modelAction
  updatePinStatus(pinStatus: PinStatus) {
    this.pinStatus = pinStatus;
  }
}
