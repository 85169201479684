import { useStyleSheet, StyleService } from '@ui-kitten/components';
import React, { forwardRef, Ref } from 'react';
import { ScrollView, StyleProp, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type Props = {
  containerStyle?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  testID?: string;
};

const ContainerScrollView = forwardRef<ScrollView, Props>(
  (props: Props, ref?: Ref<ScrollView>) => {
    const { containerStyle, children, testID } = props;
    const styles = useStyleSheet(themedStyles);
    const insets = useSafeAreaInsets();

    return (
      <ScrollView
        ref={ref}
        keyboardShouldPersistTaps="handled"
        keyboardDismissMode="on-drag"
        style={styles.scrollContainer}
        contentContainerStyle={[
          styles.contentContainer,
          { paddingBottom: insets.bottom + 32 },
          containerStyle,
        ]}
        testID={testID}
      >
        {children}
      </ScrollView>
    );
  },
);

const themedStyles = StyleService.create({
  scrollContainer: {
    flex: 1,
    marginTop: 8,
  },
  contentContainer: {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingTop: 16,
  },
});

export default ContainerScrollView;
