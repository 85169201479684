import { Text, useStyleSheet, StyleService } from '@ui-kitten/components';
import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';

type Props = {
  steps: number;
  currentSteps?: number;
  maxTab?: number;
  selectedIndex?: number;
  showPercentage?: boolean;
  setIsFinished?: (value: boolean) => void;
};

const StepProgressBar: React.FC<Props> = ({
  steps,
  currentSteps = 0,
  selectedIndex = 1,
  maxTab = 1,
  showPercentage = true,
  setIsFinished,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [width, setWidth] = useState(0);
  const translateX = useSharedValue(0);
  const [step, setStep] = useState<number>(currentSteps);

  useEffect(() => {
    if (selectedIndex >= maxTab) {
      return;
    }
    const index = selectedIndex + 1;
    let interval: any;
    if (step < Math.floor((index / maxTab) * 100)) {
      interval = setInterval(() => {
        const newStep = (step + 1) % (steps + 1);
        if (newStep >= 100) {
          setStep(steps);
          if (setIsFinished) {
            setIsFinished(true);
          }
        } else {
          setStep(newStep);
        }
      }, 0);
    } else if (index < Math.ceil(step / (100 / maxTab))) {
      setStep(Math.floor((index / maxTab) * 100));
    }
    return () => {
      clearInterval(interval);
    };
  }, [selectedIndex, step]);

  useEffect(() => {
    const currentWidth = (width * step) / steps;
    translateX.value = withTiming(-width + currentWidth, {
      duration: 0,
    });
  }, [step, width]);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: translateX.value }],
  }));

  const textAnimatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: translateX.value }],
  }));

  if (selectedIndex >= maxTab) {
    return null;
  }

  return (
    <View
      style={styles.mainContainer}
      onLayout={(e) => {
        const { width } = e.nativeEvent.layout;
        setWidth(width);
      }}
    >
      <View style={styles.container}>
        <Animated.View style={[styles.primaryBar, animatedStyle]} />
      </View>
      {showPercentage ? (
        <Animated.View style={[styles.percentContainer, textAnimatedStyle]}>
          <Text style={styles.percent}>{`${selectedIndex + 1}/${maxTab}`}</Text>
        </Animated.View>
      ) : (
        <View style={styles.spacer} />
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    marginTop: 24,
    height: 8,
    marginHorizontal: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    overflow: 'hidden',
    borderRadius: 60,
  },
  mainContainer: {
    marginHorizontal: 12,
    position: 'relative',
  },
  primaryBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'white',
    height: 8,
    borderRadius: 60,
  },
  percentContainer: {
    marginTop: 12,
    marginBottom: 4,
    alignItems: 'stretch',
  },
  percent: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_700Bold',
    color: 'white',
    alignSelf: 'flex-end',
  },
  spacer: {
    margin: 12,
  },
});

export default StepProgressBar;
