import Constants from "expo-constants";
import * as Linking from "expo-linking";
import { get } from "lodash";

import manifest from "../app.json";

const getConfig = () => {
  const version =
    Constants.manifest?.version ||
    Constants.nativeAppVersion ||
    get(manifest, "expo.version") ||
    "0.0.0";
  const build =
    Constants.manifest?.ios?.buildNumber ||
    Constants.manifest?.android?.versionCode ||
    Constants.nativeBuildVersion ||
    get(manifest, "expo.ios.buildNumber") ||
    "0";
  const slug = process.env.REACT_APP_SLUG || "";
  const config = {
    env: process.env.REACT_ENV || "undefined",
    debug: process.env.REACT_DEBUG === "True",
    mock: {
      // Enable mock payment method.
      paymentMethod:
        process.env.REACT_ENV !== "production" ||
        process.env.REACT_DEBUG === "True",
    },
    appVersion: version,
    storeKey: process.env.REACT_STORE_KEY || `${slug}-store`,
    tokenKey: process.env.REACT_TOKEN_KEY || `${slug}-token`,
    onboardingKey: process.env.REACT_STORE_KEY || `${slug}-onboarding`,
    buildVersion: `v${version} build ${build}`,

    googlePlacesApiKey: process.env.GOOGLE_PLACES_API_KEY || "",
    segmentWriteKey: process.env.SEGMENT_WRITE_KEY || "",
    appsflyerDevKey: process.env.APPSFLYER_DEV_KEY || "",
    appsflyerAppId: process.env.APPSFLYER_APP_ID || "",
    appsflyerInviteLinkId: process.env.APPSFLYER_INVITE_LINK_ID || "",
    urls: {
      ws: process.env.REACT_WS_URL!,
      api: process.env.REACT_API_URL!,
      version: "auth/version/",
      feedback: "auth/feedback/",
      signUp: "auth/registration/",
      signIn: "auth/login/",
      signInWithApple: "ugipoints/apple/login/",
      signInWithGoogle: "ugipoints/google/login/",
      signInWithFacebook: "ugipoints/facebook/login/",
      changePassword: "auth/password/change/",
      forgotPassword: "auth/password-reset-request/",
      verifyResetPasswordCode: "auth/password-verify-code/",
      resetPassword: "auth/password-reset/",
      logOut: "auth/logout/",
      launchHistory: "auth/launch-history/",
      userExists: "auth/user-exists/",
      users: "users/",
      notificationSettings: "users/me/notification_settings/",
      phoneNumbers: "users/me/phone-numbers/",
      phoneNumberExist: "users/me/phone-numbers/exist/",
      addresses: "users/me/addresses/",
      friends: "users/me/friends/",
      registerDevice: "users/me/register-device/",
      gamesurvey: "prizes/gamesurvey/",
      paperless: "user-settings/paperless/",
      products: "stores/shopify/products/",
      supplyDrop: "stores/shopify/products/supply-drop/",
      orders: "stores/shopify/orders/",
      achievements: "ugipoints/achievements/",
      verifyAchievement: "ugipoints/verify/",
      ugipoints: "ugipoints/balance/",
      ugipointsLogs: "ugipoints/all_points_activity/",
      redeemUgipoints: "ugipoints/spend/",
      dailyspin: "ugipoints/daily_spin/",
      addFundsRemainingTime: "ugipoints/add-funds-remaining-time/",
      spinPoints: "ugipoints/spin_points/",
      getPoints: "ugipoints/get_points/",
      status: "ugipoints/status/",
      ugiSweepsWinners: "ugipoints/ugisweeps/winners/",
      applications: "bank/applications/",
      documents: "bank/applications/documents/",
      accounts: "bank/accounts/",
      ugamiCards: "bank/cards/",
      ugamiVirtualCards: "bank/cards/virtual/",
      createCustomerVerificationToken: "bank/customer-token-verification/",
      createCustomerToken: "bank/customer-token/",
      createFee: "bank/fee/",
      authorizations: "bank/authorizations/",
      declinedAuthorizations: "bank/authorizations/declined/",
      transactions: "bank/transactions/",
      statements: "bank/statements/",
      streaks: "auth/streaks/",
      atms: "bank/atms/",
      linkToken: "bank/bank-accounts/plaid-link-token/",
      banks: "bank/bank-accounts/",
      recurringDeposits: "bank/recurring-deposits/",
      bookPayment: "bank/book-payments/",
      publicKey: "circle/encryption/public-key/",
      cards: "circle/cards/",
      payments: "circle/payments/",
      receivedPayments: "bank/payments/received/",
      originatedPayments: "bank/payments/originated/",
      sendEmail: "users/send-email/",
      ugamiTransferToBank: "bank/recurring-deposits/ugami-transfer/",
      astraAuthStatus: "astra/auth/status/",
      astraSendAuthorizationCode: "astra/auth/",
      astraCards: "astra/cards/",
      astraRoutines: "astra/routines/",
      directDeposit: {
        accessToken: "direct-deposit/access-token/",
        linkedAccounts: "direct-deposit/linked-accounts/",
        initialize: process.env.REACT_ATOMICFI_INITIALIZE_URL!,
      },
      bugReport:
        process.env.REACT_BUG_REPORT_URL ||
        "https://ugami.zendesk.com/hc/en-us/requests/new?ticket_form_id=6940527911821",
    },
    deepLinkPrefixes: [
      "https://*.ugami.com",
      "https://ugami.com",
      "http://*.ugami.com",
      "http://ugami.com",
      "exps://*.ugami.com",
      "exps://ugami.com",
      "ugami:///",
      "ugami://",
      "exp://127.0.0.1:19002/--/",
      Linking.createURL("/"),
    ],
    sentry: {
      dsn: process.env.SENTRY_DSN || "",
    },
  };

  return config;
};

export default getConfig();
