import { useStyleSheet, StyleService, Text } from '@ui-kitten/components';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { View, TextInput } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withRepeat,
  interpolate,
  withTiming,
  Easing,
  runOnJS,
} from 'react-native-reanimated';

type Props = {
  testID?: string;
  code: string;
  setCode: (value: string) => void;
  codeLength?: number;
  cellSpacing?: number;
  cellSize: number;
  autoFocus?: boolean;
  selectedIndex: number;
};

export type OtpInputHandle = {
  shake: () => void;
  focus: () => void;
};

const OtpInput: React.ForwardRefRenderFunction<OtpInputHandle, Props> = (
  props,
  ref,
) => {
  const {
    testID,
    code,
    setCode,
    codeLength = 6,
    cellSpacing = 2,
    cellSize,
    autoFocus = false,
    selectedIndex = 0
  } = props;
  const styles = useStyleSheet(themedStyles);
  const opacity = useSharedValue(0);
  const translateX = useSharedValue(0);
  const inputRef = useRef<TextInput>(null);

  useEffect(() => {
    opacity.value = withRepeat(withTiming(1, { duration: 500 }), -1, true);
  }, []);

  const cursorAnimatedStyle = useAnimatedStyle(
    () => ({ opacity: opacity.value }),
    [],
  );

  const cellAnimatedStyles = useAnimatedStyle(() => {
    const positionX = interpolate(
      translateX.value,
      [0, 0.5, 1, 1.5, 2, 2.5, 3],
      [0, -15, 0, 15, 0, -15, 0],
    );

    return {
      transform: [{ translateX: positionX }],
    };
  }, []);

  const resetCode = () => {
    setCode('');
    if (inputRef && inputRef.current && selectedIndex === 3) {
      inputRef.current.clear();
      inputRef.current.focus();
    }
  };

  const shake = () => {
    translateX.value = 0;
    translateX.value = withTiming(
      3,
      {
        duration: 400,
        easing: Easing.out(Easing.bounce),
      },
      (finished?: boolean) => {
        if (finished) {
          runOnJS(resetCode)();
        }
      },
    );
  };

  const focus = () => {
    if (inputRef && inputRef.current && selectedIndex === 3) {
      inputRef.current.focus();
    }
  };

  useImperativeHandle(ref, () => ({
    shake,
    focus,
  }));

  const onChangeText = (text: string) => {
    if (text.length > codeLength) {
      text = text.substring(0, codeLength);
    }
    const newText = text.replace(/[^0-9]/g, '');
    setCode(newText);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
    }
  }

  return (
    <Animated.View
      style={[
        {
          flex: 1,
          height: cellSize,
          overflow: 'hidden',
        },
        cellAnimatedStyles,
      ]}
    >
      <Animated.View
        style={[
          styles.codeContainer,
          {
            height: cellSize,
          },
        ]}
      >
        {Array.from(Array(codeLength)).map((_, index) => (
          <View
            key={index}
            style={[
              code.length === index
                ? styles.cellStyleFocused
                : styles.cellStyle,

              {
                width: cellSize,
                height: cellSize,
                marginRight: codeLength - 1 === index ? 0 : cellSpacing,
              },
            ]}
          >
            {code.length === index ? (
              <Animated.View style={[styles.cursor, cursorAnimatedStyle]} />
            ) : (
              <Text style={styles.text}>
                {code.length > index ? code[index] : ''}
              </Text>
            )}
          </View>
        ))}
      </Animated.View>
      <TextInput
        testID={testID}
        ref={inputRef}
        style={[
          styles.input,
          {
            height: cellSize,
            marginLeft: -12,
          },
        ]}
        selectionColor="transparent"
        keyboardType="number-pad"
        value={code}
        autoFocus={autoFocus}
        onBlur={focus}
        onChangeText={onChangeText}
        onKeyPress={handleKeyDown}
        pointerEvents={code.length > 0 ? 'box-only' : 'auto'}
        textContentType="oneTimeCode"
        // TODO: Uncomment once the expo version was updated
        autoComplete="sms-otp"
      />
    </Animated.View>
  );
};

const themedStyles = StyleService.create({
  codeContainer: {
    position: 'absolute',
    width: '100%',
    flexDirection: 'row',
  },
  cursor: {
    width: 1,
    height: 18,
    backgroundColor: 'white',
  },
  input: {
    outlineStyle: 'none',
    backgroundColor: 'transparent',
    width: '100%',
    fontSize: 1,
    color: 'transparent',
  },
  text: {
    fontFamily: 'SourceSansPro_600SemiBold',
    fontSize: 18,
    color: 'text-primary',
  },
  cellStyle: {
    borderColor: 'gray-02',
    borderWidth: 1,
    borderRadius: 2,
    backgroundColor: 'brand-field-background',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cellStyleFocused: {
    borderColor: 'red-03',
    borderWidth: 1,
    borderRadius: 2,
    backgroundColor: 'brand-field-background',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default React.forwardRef(OtpInput);
