export default {
  OnboardingStack: { screens: {} },
  AppStack: {
    screens: {
      CreateAccountComplete: 'sign-up-complete',
      'Apply for Ugami Card': 'apply-for-ugami-card',
      UgamiCardRejected: 'apply-for-ugami-card/rejected',
      'Not Found': '*',
    },
  },
  AuthStack: {
    screens: {
      'Create Your Account': 'sign-up',
      'Not Found': '*',
    },
  },
  PostRegistrationStack: {
    screens: {
      ConfirmEmail: 'confirm-email',
      CreateAccountComplete: 'create-account-complete',
      'Update Email': 'update-email',
    },
  },
};
