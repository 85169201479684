import {
  useStyleSheet,
  StyleService,
  Text,
  Input,
  InputProps,
  useTheme,
  Modal,
  Card
} from '@ui-kitten/components';
import { format } from 'date-fns';
import { LinearGradient } from 'expo-linear-gradient';
import { isUndefined } from 'lodash';
import React, { forwardRef, Ref, useState } from 'react';
import {
  View,
  TextStyle,
  StyleProp,
  Keyboard,
  LayoutChangeEvent,
  TouchableWithoutFeedback,
} from 'react-native';
import Calendar from 'react-calendar';

import ConditionalWrapper from '../../components/Common/ConditionalWrapper';

type Props = InputProps & {
  label?: string;
  labelStyle?: StyleProp<TextStyle>;
  size?: string;
  status?: string;
  error?: any;
  date?: Date;
  min?: Date;
  max?: Date;
  isDate?: boolean;
  isEditable?: boolean;
  onSelect?: (date: Date) => void;
  inlineLabelAccesory?: () => JSX.Element;
  labelAccesory?: () => JSX.Element;
  accessoryRight?: () => JSX.Element;
  inputAccessoryRight?: () => JSX.Element;
  onFocus?: () => void;
  onBlur?: () => void;
  paddingTop?: number;
};

const LabeledInput = forwardRef<Input, Props>(
  (props: Props, ref?: Ref<Input>) => {
    const {
      label,
      labelStyle,
      size = 'large',
      status = 'primary',
      error,
      date,
      min,
      max,
      isDate = false,
      isEditable = true,
      onSelect,
      inlineLabelAccesory,
      labelAccesory,
      accessoryRight,
      inputAccessoryRight,
      onFocus,
      onBlur,
      paddingTop = 16,
      testID,
      ...inputProps
    } = props;
    const styles = useStyleSheet(themedStyles);
    const [isFocus, setIsFocus] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [value, setValue] = useState('');
    const [width, setWidth] = useState(0);
    const theme = useTheme();
    const onInputFocus = () => {
      if (onFocus) {
        onFocus();
      }
      setIsFocus(true);
      if (isDate) {
        Keyboard.dismiss();
        setIsVisible(true);
      }
    };

    const onInputBlur = () => {
      if (onBlur) {
        onBlur();
      }
      setIsFocus(false);
    };

    const onLayout = (e: LayoutChangeEvent) => {
      if (width === 0) {
        setWidth(e.nativeEvent.layout.width);
      }
    };

    const onConfirmDate = (date: Date) => {
      setIsVisible(false); // must be first to stop datepicker showing twice
      const value = format(date, 'MM/dd/yyyy');
      if (onSelect) {
        onSelect(date);
      }
      setValue(value);
    };

    const onCancelDate = () => {
      setIsVisible(false);
    };

    const handleKeyDown = (e) => {
      if (e.key === "Tab") {
        e.preventDefault();
      }
    }

    return (
      <>
        {label ? (
          <View style={[styles.label, { paddingRight: width }]}>
            <View style={styles.row}>
              <Text style={[styles.textWithOpacity, labelStyle]}>{label}</Text>
              {!!labelAccesory && labelAccesory()}
            </View>
            {!isUndefined(inlineLabelAccesory) ? (
              inlineLabelAccesory()
            ) : !isUndefined(error) ? (
              <Text style={styles.error} testID={`${testID}Error`}>
                {error.message}
              </Text>
            ) : (
              <></>
            )}
          </View>
        ) : (
          <View style={{ paddingRight: width, paddingTop }}>
            {!!error && <Text style={styles.error}>{error.message}</Text>}
          </View>
        )}
        <View style={styles.row}>
          <LinearGradient
            colors={[
              error
                ? theme['orange-03']
                : isFocus
                  ? theme['red-03']
                  : 'transparent',
              'transparent',
            ]}
            style={
              isFocus || !isUndefined(error)
                ? styles.gradientInputContainer
                : styles.inputContainer
            }
          >
            <ConditionalWrapper
              condition={isDate}
              wrapper={(children: any) => (
                <TouchableWithoutFeedback
                  onPress={() => setIsVisible(true)}
                  style={styles.input}
                  testID={testID}
                >
                  {children}
                </TouchableWithoutFeedback>
              )}
            >
              <ConditionalWrapper
                condition={!isEditable}
                wrapper={(children: any) => (
                  <TouchableWithoutFeedback onPress={inputProps.onPressIn}>
                    <View style={styles.input}>
                      {children}
                      <View
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </View>
                  </TouchableWithoutFeedback>
                )}
              >
                <Input
                  ref={ref ? ref : undefined}
                  style={
                    !isUndefined(error) || isFocus ? styles.focus : styles.input
                  }
                  onKeyPress={handleKeyDown}
                  size={size}
                  status={status}
                  accessoryRight={inputAccessoryRight}
                  showSoftInputOnFocus={!isDate}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                  {...(isDate ? { value } : {})}
                  testID={testID}
                  {...inputProps}

                />
              </ConditionalWrapper>
            </ConditionalWrapper>
          </LinearGradient>
          <View onLayout={onLayout}>{accessoryRight && accessoryRight()}</View>
        </View>
        {isDate && (
          <Modal
            visible={isVisible}
            backdropStyle={styles.backdrop}
            onBackdropPress={onCancelDate}
          >
            <Card disabled={true} style={{ backgroundColor: '#292828' }}>
              <Calendar
                onChange={onConfirmDate}
                value={date}
                maxDate={max}
                minDate={min}
              />
            </Card>
          </Modal>
        )}
      </>
    );
  },
);

const themedStyles = StyleService.create({
  inputContainer: {
    flex: 1,
    alignItems: 'center',
    minHeight: 48,
    padding: 1,
    marginTop: 8,
  },
  gradientInputContainer: {
    flex: 1,
    alignItems: 'center',
    minHeight: 48,
    borderRadius: 2,
    padding: 1,
    marginTop: 8,
  },
  input: {
    alignSelf: 'stretch',
  },
  dateInput: {
    width: '100%',
    minHeight: 48,
  },
  focus: {
    alignSelf: 'stretch',
    borderColor: 'transparent',
    backgroundColor: 'brand-field-focused-background',
    borderRadius: 2,
  },
  textWithOpacity: {
    fontSize: 14,
    fontFamily: 'SourceSansPro_400Regular',
    alignSelf: 'flex-start',
    opacity: 0.5,
  },
  label: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 4,
    paddingTop: 24,
  },
  error: {
    fontSize: 12,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'orange-03',
    textTransform: 'lowercase',
    textAlign: 'right',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  placeholder: {
    fontSize: 16,
    fontFamily: 'SourceSansPro_400Regular',
    color: 'text-placeholder',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});

export default LabeledInput;
