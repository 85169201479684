// import MaskedView from '@react-native-masked-view/masked-view';
import { useStyleSheet, StyleService } from '@ui-kitten/components';
// import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
// import { View } from 'react-native';

type Props = object;

// TODO: find replacement MaskedView
const MainContainer: React.FC<Props> = ({ children }) => {
  const styles = useStyleSheet(themedStyles);
  return (
    <>{children}</>
    // <View style={styles.container}>
    //   <LinearGradient
    //     colors={['transparent', 'white']}
    //     style={styles.gradient}
    //   />
    //   <View style={styles.gradientContainer} />
    // </View>
    // <MaskedView
    //   renderToHardwareTextureAndroid
    //   style={styles.container}
    //   maskElement={
    //     <View style={styles.container}>
    //       <LinearGradient
    //         colors={['transparent', 'white']}
    //         style={styles.gradient}
    //       />
    //       <View style={styles.gradientContainer} />
    //     </View>
    //   }
    // >
    //   {children}
    // </MaskedView>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  gradientContainer: {
    flex: 1,
    backgroundColor: 'white',
  },
  img: {
    width: '100%',
    height: 120,
    resizeMode: 'stretch',
  },
  gradient: {
    width: '100%',
    height: 30,
  },
});

export default MainContainer;
