import {
  model,
  Model,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

@model('ugami-app/CustomerToken')
export default class CustomerToken extends Model({
  token: prop<string>(''),
  expirationDate: prop<number>(0),
}) {
  @modelAction
  update(data: ModelCreationData<CustomerToken>) {
    Object.assign(this, data);
  }
}
