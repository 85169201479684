import {
  createStackNavigator,
  StackNavigationProp,
} from "@react-navigation/stack";
import React from "react";

import ExternalLink from "../../components/Common/ExternalLink";
import SignUp from "../../containers/SignUp";

export type AuthStackParamList = {
  "Sign In": undefined;
  "Create Your Account": undefined;
  "Forgot Password": undefined;
  SixDigitCode: { email: string };
  "Password Reset": { sixDigitCode: string };
  "Not Found": undefined;
  "External Link": { url: string; title?: string };
  "Ugami Account": { isAccountCreated: boolean };
};

export type Test = {
  test: undefined;
};
export type AuthStackNavigation = StackNavigationProp<AuthStackParamList>;
type Props = object;

const Stack = createStackNavigator<AuthStackParamList>();

const AuthStack: React.FC<Props> = (props) => {
  return (
    <Stack.Navigator
      screenOptions={{
        cardStyle: { backgroundColor: "transparent" },
        animationEnabled: false,
        headerShown: false,
        presentation: "modal",
      }}
    >
      <Stack.Screen name="Create Your Account" component={SignUp} />
      <Stack.Screen name="External Link" component={ExternalLink} />
    </Stack.Navigator>
  );
};

export default AuthStack;
